import axios from "@axios";
import store from "@/store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
const collegeContacts = () => {
  var collegeCode = store.state.mainUserCodes.CollegeCode
  var collegeContactsList = []
  var totalTpo = 0
  var parsedTPO = 0
  var result = new Promise((resolve, reject) => {
  firebase.firestore().collection("TPOid").where("CollegeCode",'==', collegeCode).get().then((tpos)=>{
    totalTpo = tpos.size
    tpos.forEach((tpo) =>{
      // console.log(tpo.FullName)
      var obj = {};
                    obj = {
                      id: tpo.id,
                      fullName: tpo.data().FullName,
                      avatar: tpo.data().photo,
                      status: "offline",
                      role: "tpo",
                      about: tpo.data().Designation ?? ''
                    };
                    collegeContactsList.push(obj)
                    parsedTPO = parsedTPO + 1
    })
  }).then(()=>{
    if(totalTpo == parsedTPO){
      resolve(collegeContactsList)
    }
  })
})
return result
}
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchContactbyId(ctx, {studentId}){
      // console.log(`this is student id ${studentId}`)
      // var studentId = payload.id;
      var studentCode = store.state.mainUserCodes.StudentCode
      return new Promise(async(resolve, reject) => {
        var uid = firebase.auth().currentUser.uid;
        if (uid && this.$app.$acl.get == 'recruiter') {
          const Ref = firebase.firestore().collection('StudentID').doc(studentId)
          Ref.get().then((doc) => {
            console.log(doc.data())
            // && doc.data().StudentCode === studentCode
            if(doc.exists && doc.data().StudentCode === studentCode){
              // console.log('all condition satisfied')
              var obj = {};
                    obj = {
                      id: doc.id,
                      fullName: doc.data().FullName,
                      avatar: doc.data().profilephoto,
                      collegeName: doc.data().CollegeName,
                      status: "offline",
                      role: "student", 
                      about: doc.data().Bio ?? '',
                    };
                    resolve(obj)
            }else{
              reject('no user')
            }
          })
        }
      })
    },
    fetchChatsAndContacts() {
      return new Promise(async(resolve, reject) => {
        // axios
        //   .get('/apps/chat/chats-and-contacts')
        //   .then(response => resolve(response))
        //   .catch(error => reject(error))

        //fetching chats from firebase database
        var uid = firebase.auth().currentUser.uid;
        // console.log(`this is current user in chat ${uid}`);
        if (uid && this.$app.$acl.get == 'recruiter') {
          let chatsList = [
            {
              id: "flairboat-chat-admin",
              fullName: "Flairboat Support",
              collegeName: "Flairboat Private Limited",
              avatar: require('@/assets/images/logo/logo.png'),
              status: "online",
              role: "support",
              chat: {
                unseenMsgs: 0,
                lastMessage: {
                  message:
                    "Welcome to Flairboat! I am your Flairboat Chat Support. I am available 24*7 to help you.",
                  time: new Date(),
                  senderId: "flairboat-chat-admin",
                  files: "",
                },
              },
            },
          ];
          let snap1size = 0;
          let snap2 = 0;

          var starRef = firebase
            .database()
            .ref("Recruiter")
            .child(uid);
          starRef.on("value", (snapshot) => {
            snap1size = snapshot.numChildren;
            snapshot.forEach((doc) => {
              var key = doc.key;
              let Ref = firebase
                .firestore()
                .collection("StudentID")
                .doc(key)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    var obj = {};
                    obj = {
                      id: doc.id,
                      fullName: doc.data().FullName,
                      avatar: doc.data().profilephoto,
                      collegeName: doc.data().CollegeName,
                      status: "offline",
                      role: "student",
                      chat: {
                        unseenMsgs: 0,
                        lastMessage: {
                          message: "How can we help? We're here for you!",
                          time: "Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)",
                          senderId: 11,
                          files: "",
                        },
                      },
                    };
                    chatsList.push(obj);
                  }
                });
            });
          });
          var CountRef = firebase.database().ref(uid);
          CountRef.once("value", (snapshot) => {
            snap2 = snapshot.numChildren;
            snapshot.forEach((doc) => {
              var keys = doc.key;
              firebase
                .firestore()
                .collection("TPOid")
                .doc(keys)
                .get()
                .then((docs) => {
                  if (docs.exists) {
                    var obj = {};
                    obj = {
                      id: docs.id,
                      fullName: docs.data().FullName,
                      avatar: docs.data().photo,
                      status: "offline",
                      role: "tpo",
                      chat: {
                        unseenMsgs: 1,
                        lastMessage: {
                          message: "How can we help? We're here for you!",
                          time: "Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)",
                          senderId: 11,
                          files: "",
                        },
                      },
                    };
                    chatsList.push(obj);
                  }
                });
            });
          });
          // console.log(chatsList);
          let userInfo =
            JSON.parse(localStorage.getItem("userInfo")) || state.AppActiveUser;
          const profileUserData = {
            id: uid,
            avatar: userInfo.avatar || userInfo.profilephoto,
            fullName: userInfo.fullName || userInfo.displayName || userInfo.username,
            status: userInfo.status,
          };
          var contactsList = await collegeContacts()
          var response = {
            chatsList: chatsList,
            profileUser: profileUserData,
            contactsList:contactsList
          };
          resolve(response);
        } else {
          reject("no user");
        }
      });
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/chat/users/profile-user")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getChat(ctx, { userId, role }) {
      return new Promise((resolve, reject) => {
        // axios
        //   .get(`/apps/chat/chats/${userId}`)
        //   .then((response) => resolve(response))
        //   .catch((error) => reject(error));
        var uid = firebase.auth().currentUser.uid;
        if (uid) {
          const chats = {
            userId: 1,
            unseenMsgs: 0,
            chat: [],
          };
          if (userId === "flairboat-chat-admin") {
            var obj = {
              message:
                "Welcome to Flairboat! I am your Flairboat Chat Support. I am available 24*7 to help you.",
              isSent: false,
              time: new Date(),
              senderId: userId,
              files: "",
            };
            //                        var obj ={msg :{
            //     textContent: 'Welcome to Flairboat ! I am your Flairboat Chat Support. I am available 24*7 to help you.',
            //     isSent:false,
            //     time:new Date()
            // }}

            var messageRef = firebase
              .database()
              .ref("flairboat-chat-admin")
              .child(uid);
            messageRef.orderByChild("timestamp").once("child_added", (data) => {
              //  //console.log(data.val())obj=data.val                           // console.log(data.val())
              var obj2 = data.val();
              if (obj2.msg) {
                obj2.message = obj2.msg.textContent;
                obj2.time = obj2.msg.time;
                obj2.files = obj2.msg.attachment;

                if (obj2.msg.role === "post") {
                  obj2.msg.isSent = true;
                  obj2.isSent = obj2.msg.isSent;
                  obj2.senderId = uid;
                } else if (obj2.msg.role === "support") {
                  obj2.msg.isSent = false;
                  obj2.isSent = obj2.msg.isSent;
                  obj2.senderId = userId;
                }
                chats.chat.push(obj2);
              }
            });
            chats.chat.push(obj);
            resolve(chats);
          } else {
            var obj = {};
            if (role == "student") {
              const messageRef = firebase
                .database()
                .ref(userId)
                .child(uid);
              messageRef.orderByChild("timestamp").on("child_added", (data) => {
                obj = data.val();
                // console.log(obj);
                obj.message = obj.msg.textContent;
                obj.time = obj.msg.time;
                obj.files = obj.msg.attachment;
                if (obj.msg.role === "post") {
                  obj.msg.isSent = true;
                  obj.isSent = obj.msg.isSent;
                  obj.senderId = uid;
                } else if (obj.msg.role === "editor") {
                  obj.msg.isSent = false;
                  obj.isSent = obj.msg.isSent;
                  obj.senderId = userId;
                }
                chats.chat.push(obj);
              });
              resolve(chats);
            } else if (role == "tpo") {
              var Ref = firebase
                .database()
                .ref(uid)
                .child(userId);
              Ref.orderByChild("timestamp").on("child_added", (data) => {
                //  //console.log(data.val())obj=data.val()
                obj = data.val();
                obj.message = obj.msg.textContent;
                obj.time = obj.msg.time;
                obj.files = obj.msg.attachment;

                if (obj.msg.role === "admin") {
                  obj.msg.isSent = true;
                  obj.isSent = obj.msg.isSent;
                  obj.senderId = uid;
                } else if (obj.msg.role === "post") {
                  obj.msg.isSent = false;
                  obj.isSent = obj.msg.isSent;
                  obj.senderId = userId;
                }
                chats.chat.push(obj);
              });
              resolve(chats);
            }
          }
        }
      });
    },
    sendMessage(ctx, payload) {
      return new Promise((resolve, reject) => {
        // axios
        //   .post(`/apps/chat/chats/${contactId}`, { message, senderId })
        //   .then((response) => resolve(response))
        //   .catch((error) => reject(error));
        // console.log("in store");

        let contactId = payload.contactId;
        let message = payload.message;
        let senderId = payload.senderId;
        let chatrole = payload.chatrole;
        let files = payload.files;

        // console.log(chatrole);
        // console.log(contactId);
        // console.log(senderId);
        // console.log(message);
        // console.log(files)
        const newMessageData = {
          message: message,
          time: new Date(),
          senderId: senderId,
          role: "post",
          isSent: true,
          files: files,
        };
        var role = chatrole;
        const u = firebase.auth().currentUser;
        let userInfo =
          JSON.parse(localStorage.getItem("userInfo")) || state.AppActiveUser;
        // console.log(uid)
        if (contactId === "flairboat-chat-admin") {
          const thisIns = this;
          // var role = thisIns.$acl.get[0];
          var Rec = firebase
            .database()
            .ref("flairboat-chat-admin/" + u.uid)
            .update(
              {
                id: u.uid,
                role: role,
                username: userInfo.username,
                email: userInfo.email,
                profilephoto: userInfo.avatar,
              },
              function(error) {
                if (error) {
                  console.log(error);
                } else {
                  // console.log("Saved")
                  // console.log(thisIns.typedMessage)
                  var Ref = firebase
                    .database()
                    .ref(contactId)
                    .child(u.uid)
                    .push();
                  Ref.set(
                    {
                      isPinned: true,
                      msg: {
                        role: "post",
                        // 'username'    :u.username,
                        textContent: message,
                        time: String(new Date()),
                        isSent: true,
                        isSeen: false,
                        attachment: files,
                      },
                    },
                    function(error) {
                      if (error) {
                        console.log(error);
                      } else {
                        console.log("Saved");
                        // resolve( {newMessageData });
                      }
                    }
                  );
                }
              }
            );
        } else {
          var id = contactId;
          //console.log(role)
          if (role == "student") {
            var Ref = firebase
              .database()
              .ref(id)
              .child(u.uid)
              .push();
            Ref.set(
              {
                isPinned: true,
                msg: {
                  role: "post",
                  textContent: message,
                  time: String(new Date()),
                  isSent: true,
                  isSeen: false,
                  attachment: files,
                },
              },
              function(error) {
                if (error) {
                  //console.log(error)
                } else {
                  //console.log("Saved")

                  var Rec = firebase
                    .database()
                    .ref("Recruiter/" + u.uid)
                    .child(id)
                    .set({
                      id: id,
                    })
                    .then(() => {
                      // resolve({ newMessageData });
                    });
                }
              }
            );
          } else if (role == "tpo") {
            var Ref = firebase
              .database()
              .ref(u.uid)
              .child(id)
              .push();
            Ref.set(
              {
                isPinned: true,
                msg: {
                  role: "post",
                  textContent: message,
                  time: String(new Date()),
                  isSent: true,
                  isSeen: false,
                  attachment: files,
                },
              },
              function(error) {
                if (error) {
                  //console.log(error)
                } else {
                  //console.log("Saved")

                  var Rec = firebase
                    .database()
                    .ref("TPO+Recruiter/" + id)
                    .child(u.uid)
                    .set({
                      id: u.uid,
                    })
                    .then(() => {
                      resolve({ newMessageData });
                    });
                }
              }
            );
          }
        }
        resolve({ newMessageData });
      });
    },
  },
};
