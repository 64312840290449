import mock from "@/@fake-db/mock";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import store from "@/store";

const recDashboardData = async () => {
    var studentCode = store.state.mainUserCodes.StudentCode;
  var collegeCode = store.state.mainUserCodes.CollegeCode;
  var uid = firebase.auth().currentUser.uid;

  let finalData = {
      jobsArray :[],
      totalOpenings:0,
      shortlistedCand:0,
      hiredCand:0,
      totalApplied:0,
      meetup:null
  };
  let result = await new Promise((resolve, reject) => {
      var totalParsedJobs = 0
      let recRef = firebase.firestore().collection("RecruiterJobs").where("RecruiterId", "==",uid).where("Colleges","==", studentCode)
      recRef.limit(3).get().then((jobs) => {
          jobs.forEach((job) => {
              var obj = job.data()
              obj.id = job.id
              finalData.totalApplied = finalData.totalApplied + (job.data().TotalApplied ?? 0)
              finalData.jobsArray.push(obj)
              finalData.totalOpenings = finalData.totalOpenings + parseInt(job.data().vacancies)
              let candiRef = firebase.firestore().collection("RecruiterJobs").doc(job.id)
              candiRef.collection("AppliedCandidates").where("Shortlisted","==",true).get().then((candidates) => {
                  finalData.shortlistedCand = finalData.shortlistedCand + candidates.size
                  candidates.forEach((cand) => {
                      if(cand.data().isHired){
                          finalData.hiredCand = finalData.hiredCand + 1
                      }
                  })
                  totalParsedJobs = totalParsedJobs + 1
              }).finally(() => {
                  if(totalParsedJobs == jobs.size){
                  resolve(finalData)
                  }
              })
          })
      })
  });

  return result;
}

mock.onGet("/recruiter-dashboard/data").reply(async () => {
    let firebaseData = await recDashboardData();
    // console.log(firebaseData)
    // let jobAarray = []
  
    const dashboardAnalytics = {
      statisticsItems: [
        {
            icon: "fi-rr-briefcase",
            color: "primary",
            title: firebaseData.totalOpenings,
            subtitle: "Openings",
            customClass: "mb-3 ",
          },
        
          {
            icon: "fi-rr-user",
            color: "warning",
            title: firebaseData.totalApplied,
            subtitle: "Applications",
            customClass: "mb-3 mb-sm-0",
          },
        {
          icon: "fi-rr-building",
          color: "danger",
          title: firebaseData.shortlistedCand,
          subtitle: "Shortlisted",
          customClass: "mb-3 ",
        },
        
        {
          icon: "fi-rr-rocket",
          color: "success",
          title: firebaseData.hiredCand,
          subtitle: "Hired",
          customClass: "mb-3 mb-sm-0",
        },
      ],
      
      companyTable: firebaseData.jobsArray,
      meetup: firebaseData.meetup,
      
    };
    return [200, dashboardAnalytics];
  });