export default {
  SET_STUDENT_DATA(state,payload){
    state.connectedStudent = payload;
  },
  SET_MEETING(state, payload) {
    localStorage.setItem("meeting", JSON.stringify(payload));
    state.meeting = payload;
  },
  START(state, payload) {
    console.log("start trigeered");
    state.started = true;
    state.meetingId = payload.meetingId;
    state.name = payload.name;
  },
  JOIN(state, payload) {
    console.log("join trigeered");
    // console.log(payload)
    state.stream = payload.stream;
    state.meeting = payload.meeting;
    state.meetingDetail = payload.meetingDetail;
    // console.log(state)
  },
  JOINED(state, payload) {
    console.log("joinedd triggered");
    console.log(state);
  },
  CHECK_CONNECTION(state, dispatch) {
    state.meeting.on("connection", (connection) => {
      console.log(connection);
      console.log("here connecting");
     dispatch("interview/newConnection", connection);
      // console.log(store.state.interview.connections)
      // thisIns.connections = [];
      var totalConnections = state.connections;
      totalConnections.forEach((connect) => {
        connect.userId != "" ? state.connections = [...state.connections, connect] : null;
      });
    });
  },
  NEW_CONNECTION(state, payload) {
    console.log('new connection');
    state.connections = [...state.connections, payload.connection];
  },
  USER_LEFT(state, payload) {
    state.connections = payload.connections;
  },
  MEETING_ENDED(state, payload) {
    // state.initialState = initialState
    state.connections = [];
  },
  VIDEO_TOGGLE(state, payload) {
    state.videoEnabled = payload.videoEnabled;
  },
  AUDIO_TOGGLE(state, payload) {
    const audioEnableValue =  !!state.meeting.toggleAudio();
    state.audioEnabled = audioEnableValue;
  },
  CONNECTION_SETTING_CHANGE(state, payload) {
    state.connections = [...state.connections];
  },
  END(state) {
    state.stream = null;
    state.connections = [];
    state.userId = "";
    state.started = false;
    state.meetingId = "";
    state.name = "";
    state.meeting = null;
    state.meetingDetail = null;
    state.videoEnabled = true;
    state.audioEnabled = true;
    state.messages = [];
    localStorage.removeItem("meeting");
    localStorage.removeItem("meetingId");
  },
  MEETING_ENDED(state) {
    state.stream = null;
    state.connections = [];
    state.userId = "";
    state.started = false;
    state.meetingId = "";
    state.name = "";
    state.meeting = null;
    state.meetingDetail = null;
    state.videoEnabled = true;
    state.audioEnabled = true;
    state.messages = [];
    localStorage.removeItem("meeting");
    localStorage.removeItem("meetingId");
  },
  LEAVE(state) {
    state.stream = null;
    state.connections = [];
    state.userId = "";
    state.started = false;
    state.meetingId = "";
    state.name = "";
    state.meeting = null;
    state.meetingDetail = null;
    state.videoEnabled = true;
    state.audioEnabled = true;
    state.messages = [];
    localStorage.removeItem("meeting");
    localStorage.removeItem("meetingId");
  }

  // meetingInitialState(state , action){
  //     console.log(action)
  //     switch (action.type) {
  //         case START:
  //             return {
  //                 ...state,
  //                 started: true,
  //                 meetingId: action.payload.meetingId,
  //                 name: action.payload.name,
  //             };
  //         case JOIN:
  //             return {
  //                 ...state,
  //                 meeting: action.payload.meeting,
  //                 meetingDetail: action.payload.meetingDetail,
  //                 stream: action.payload.stream,
  //                 userId: action.payload.userId,
  //                 name: action.payload.name,
  //             };
  //         case JOINED:
  //             return { ...state };
  //         case NEW_CONNECTION:
  //             return {
  //                 ...state,
  //                 connections: [...state.connections, action.payload.connection],
  //             };
  //         case USER_LEFT:
  //             return { ...state, connections: [...action.payload.connections] };
  //         case LEAVE:
  //         case END:
  //         case MEETING_ENDED:
  //             return { ...state, ...initialState, connections: [] };
  //         case VIDEO_TOGGLE:
  //             return { ...state, videoEnabled: action.payload.videoEnabled };
  //         case AUDIO_TOGGLE:
  //             return { ...state, audioEnabled: action.payload.audioEnabled };
  //         case CONNECTION_SETTING_CHANGE:
  //             return { ...state, connections: [...state.connections] };
  //         case SEND_MESSAGE:
  //         case RECIEVE_MESSAGE:
  //             return { ...state, messages: [...state.messages, action.payload.message] };
  //         default:
  //             return { ...state };
  //     }
  // }
};
