// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

export const $chartColors = [
   '#039be5',
'#58a9e7',
'#7fb6e9',
'#9ec5eb',
'#bbd3ed',
'#d6e2ef',
'#f7e8d2',
'#fbe0b4',
'#fdd895',
'#fed076',
'#fdc855',
'#fbc02d',
'#043c57',
'#365870',
'#5c7589',
'#8294a3',
'#a8b3be',
'#ced4da',
'#f7d9d4',
'#f4bbb3',
'#ef9d93',
'#e87f74',
'#de5f56',
'#d23a3a',
]
// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'Flairboat', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/logo.svg'), // Will update logo in navigation menu (Branding)
  },
  layout: {
    isRTL: false,
    skin: 'bordered', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: false,
    enableScrollToTop: true,
  },
}
