export default [
  {
    path: "/apps/calendar",
    name: "apps-calendar",
    component: () => import("@/views/apps/calendar/Calendar.vue"),
    meta: {
      rule: "admin",
      authRequired: true,

    }
  },
  {
    path: "/apps/recruiter-calendar",
    name: "apps-calendar-recruiter",
    component: () => import("@/views/apps/calendar/Calendar.vue"),
    meta: {
      rule: "recruiter",
      authRequired: true,

    }
  },

  // *===============================================---*
  // *--------- RECRUITER POST JOB  ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/post-new-job",
    name: "post-new-job",
    component: () => import("@/views/recruiterpages/postJobPages/postJob.vue"),
    meta: {
      rule: "recruiter",
      authRequired: true,

    }
  },
  // *===============================================---*
  // *--------- ALL JOBS TABLE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/all-jobs",
    name: "all-jobs",
    component: () => import("@/views/tpopages/all-jobs-table/AllJobsTable.vue"),
    meta: {
      rule: "admin",
      authRequired: true,

    }
  },


  // *===============================================---*
  // *--------- STUDENTS LIST ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/allstudents/list",
    name: "all-students-list",
    component: () => import("@/views/tpopages/allStudents/UsersList.vue"),
    meta: {
      rule: "admin",
      authRequired: true,

    }
  },
  // *===============================================---*
  // *--------- CANDIDATES LIST ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/all-candidates/list",
    name: "all-candidates-list",
    component: () => import("@/views/recruiterpages/allCandidates/UsersList.vue"),
    meta: {
      rule: "recruiter",
      authRequired: true,

    }
  },
  // *===============================================---*
  // *--------- INTERVIEWS LIST ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/all-interviews/list",
    name: "all-interviews-list",
    component: () => import("@/views/recruiterpages/interviewsList/allInterviews.vue"),
    meta: {
      rule: "recruiter",
      authRequired: true,

    }
  },


  // *===============================================---*
  // *--------- RECRUITERS LIST ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/allrecruiters/list",
    name: "all-recruiters-list",
    component: () =>
      import("@/views/tpopages/allRecruiters/RecruitersList.vue"),
    meta: {
      rule: "admin",
      authRequired: true,

    }
  },


  // *===============================================---*
  // *--------- PLACEMENT REPORT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/placement-report",
    name: "placement-report",
    component: () =>
      import("@/views/tpopages/PlacementReport/PlacementReport.vue"),
    meta: {
      rule: "admin",
      authRequired: true,

    }
  },


  // *===============================================---*
  // *--------- Student PROFILE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/student-profile/:studentId",
    name: "student-profile",
    component: () =>
      import("@/views/tpopages/StudentProfile/StudentProfile.vue"),
    meta: {
      rule: 'admin',
      authRequired: true,

    },
  },
  // *===============================================---*
  // *--------- RECRUITER Student PROFILE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/candidate-profile/:studentId",
    name: "candidate-profile",
    component: () =>
      import("@/views/tpopages/StudentProfile/StudentProfile.vue"),
    meta: {
      rule: 'recruiter',
      authRequired: true,

    },
  },


  // *===============================================---*
  // *--------- JOB PROFILE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/job-profile/:jobId",
    name: "job-profile",
    component: () => import("@/views/tpopages/JobProfile/JobProfile.vue"),
    meta: {
      rule: 'admin',
      authRequired: true,

    }
    // meta: {
    //   contentRenderer: 'sidebar-left',
    //   contentClass: 'email-application',
    // },
  },
  // *===============================================---*
  // *--------- COMPANY PROFILE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/company-profile",
    name: "company-profile",
    component: () => import("@/views/tpopages/CompanyProfile/CompanyProfile.vue"),
    meta: {
      rule: "admin",
      authRequired: true,

    }
    // meta: {
    //   contentRenderer: 'sidebar-left',
    //   contentClass: 'email-application',
    // },
  },

  // *===============================================---*
  // *--------- ALL NOTICES TAB ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/notification-timeline",
    name: "all-notices",
    component: () => import("@/views/tpopages/Notices/AllNotices.vue"),
    meta: {
      rule: "admin",
      authRequired: true,
    }
  },
// *===============================================---*
  // *--------- Recruiter Account Settings  -------------------------*
  // *===============================================---*
  {
    path: '/pages/general-settings',
    name: 'pages-general-settings-recruiter',
    component: () => import('@/views/recruiterpages/companyInfoPages/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      rule:"recruiter",
      authRequired: true,
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- TPO Account Settings  -------------------------*
  // *===============================================---*
  {
    path: '/pages/profile-settings',
    name: 'pages-profile-settings',
    component: () => import('@/views/tpopages/collegeInfoPages/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      rule:"admin",
      authRequired: true,
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/chat",
    name: "apps-chat",
    component: () => import("@/views/apps/chat/Chat.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
      rule: "admin"
    },
  },

  // *===============================================---*
  // *--------- RECRUITER CHAT  -------------------------*
  // *===============================================---*
  {
    path: "/apps/conversation",
    name: "apps-chat-recruiter",
    component: () => import("@/views/apps/recruiter-chat/Chat.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
      rule: "recruiter"
    },
  },
  // *===============================================---*
  // *--------- CALLING PAGE  -------------------------*
  // *===============================================---*
  {
    path: '/interviewroom/start',
    name: 'interview-room',
    component: () => import('@/views/recruiterpages/CallingComponents/CallPage.vue'),
    meta: {
      layout: 'full',
      rule:"recruiter"
    },
  },
  {
    path: '/callingroom/start',
    name: 'calling-room',
    component: () => import('@/views/recruiterpages/CallingComponents/CallPage.vue'),
    meta: {
      layout: 'full',
      rule:"admin"
    },
  },
  
];
