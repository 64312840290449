import store from "@/store";

export default{

    getmeeting : state => {
        return state.meeting
    },

    getConnections : async (state,  getters) => {
        const meeting =  getters.getmeeting
        const connections  = [];
       await meeting.on("connection" , connection => {
            console.log('we are getting connections');
            store.dispatch("interview/newConnection", connection)
            var totalConnections = state.interview.connections;
            totalConnections.forEach((connect) => {
                connect.userId != "" ? connections.push(connect) : null;
              });
        });
        return connections
    },
    
    
}