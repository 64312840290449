import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import UIcon from '@core/components/uicons-icon/UIcon.vue'
import VueTimeago from 'vue-timeago'
import VModal from 'vue-js-modal'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(UIcon.name, UIcon)
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
})
Vue.use(VModal)


