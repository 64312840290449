import PeerConnection from './peer-connection';

var ConnectionType = 'incoming' | 'outgoing' | 'self';

var ConnectionOptions =  {
    userId: '',
    connectionType: ConnectionType,
    name: '',
    stream: MediaStream,
    videoEnabled: false,
    audioEnabled: false,
    muted: true
}

export default class Connection extends PeerConnection {
    userId = null ;
    connectionType = 'outgoing';
    name = '';
    videoEnabled = true;
    audioEnabled = true;
    muted = false;

    constructor(options) {
        super({ stream: options.stream });
        this.userId = options.userId;
        this.connectionType = options.connectionType;
        this.name = options.name;
        this.audioEnabled = options.audioEnabled;
        this.videoEnabled = options.videoEnabled;
        this.muted = !!options.muted;
    }

    toggleVideo(videoEnabled) {
        this.videoEnabled = videoEnabled;
    }

    toggleAudio(audioEnabled) {
        this.audioEnabled = audioEnabled;
    }
}
