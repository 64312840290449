export default [
  {
    path: '/dashboard/home',
    name: 'home-dashboard',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    meta:{
      authRequired :true,
      rule: "admin"
    }
  },
  {
    path: '/recruiter/home',
    name: 'home-recruiter',
    component: () => import('@/views/dashboard/recruiter/Recruiter.vue'),
    meta:{
      authRequired :true,
      rule: "recruiter"
    }
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard-home',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  // },
]
