import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import chatStoreModule from '@/views/apps/chat/chatStoreModule'
import recruiterchatStoreModule from '@/views/apps/recruiter-chat/chatStoreModule'
import moduleInterview from './interview-call/moduleInterview'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state : {
    // mainUserCodes : null,
    mainUserCodes:{
      CollegeCode:'ABC123',
      StudentCode :'XYZ123',
      user_id:null,
      RecruiterCode:null,
      CollegeLogo: require("@/assets/images/misc/collegeLogo.png")
    },
  },
  modules: {
    app,
    auth,
    appConfig,
    verticalMenu,
    // 'app-ecommerce': ecommerceStoreModule,
    'app-chat' : chatStoreModule,
    'app-recruiter-chat' : recruiterchatStoreModule,
    'interview' : moduleInterview
  },
  strict: process.env.DEV,
  mutations :{
    UPDATE_MAINUSER_CODES(state, payload){
      // console.log(payload)
      Object.assign(state.mainUserCodes, payload)
    }
  },
  actions: {
    updateMainUserCodes({ commit }, payload){
      commit("UPDATE_MAINUSER_CODES", payload);
    },
    updateAclChange(state,payload){
      // console.log(payload)
      // console.log(this)
      this.$app.$acl.change(payload)
    }
  }
})
