import mock from "@/@fake-db/mock";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import store from "@/store";

const date = new Date();
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const data = {
  events: [
    {
      id: 1,
      url: "",
      title: "Design Review",
      start: date,
      end: nextDay,
      allDay: false,
      extendedProps: {
        calendar: "Business",
      },
    },
    {
      id: 2,
      url: "",
      title: "Meeting With Client",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: true,
      extendedProps: {
        calendar: "Business",
      },
    },
    {
      id: 3,
      url: "",
      title: "Family Trip",
      allDay: true,
      start: new Date(date.getFullYear(), date.getMonth() + 1, -9),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -7),
      extendedProps: {
        calendar: "Holiday",
      },
    },
    {
      id: 4,
      url: "",
      title: "Doctor's Appointment",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: true,
      extendedProps: {
        calendar: "Personal",
      },
    },
    {
      id: 5,
      url: "",
      title: "Dart Game?",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: "ETC",
      },
    },
    {
      id: 6,
      url: "",
      title: "Meditation",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: "Personal",
      },
    },
    {
      id: 7,
      url: "",
      title: "Dinner",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: "Family",
      },
    },
    {
      id: 8,
      url: "",
      title: "Product Review",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: "Business",
      },
    },
    {
      id: 9,
      url: "",
      title: "Monthly Meeting",
      start: nextMonth,
      end: nextMonth,
      allDay: true,
      extendedProps: {
        calendar: "Business",
      },
    },
    {
      id: 10,
      url: "",
      title: "Monthly Checkup",
      start: prevMonth,
      end: prevMonth,
      allDay: true,
      extendedProps: {
        calendar: "Personal",
      },
    },
  ],
};

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------

const calendarData = async () => {
  // const userData = getUserData();
  // console.log(store.state.mainUserCodes)
  var studentCode = store.state.mainUserCodes.StudentCode;
  var collegeCode = store.state.mainUserCodes.CollegeCode;
  var uid = firebase.auth().currentUser.uid;
  let calendarEventsList = [];
  let result = await new Promise((resolve, reject) => {
    if (uid) {
      firebase
        .firestore()
        .collection("CollegeCode")
        .doc(collegeCode)
        .collection("CalendarEvents")
        .where("TPOid", "==", uid)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            var obj = doc.data();
            if (!doc.data().isDeleted) {
              obj.id = doc.id;
              obj.extendedProps.calendar = doc.data().calendar;
              calendarEventsList.push(obj);
            }
          });
        })
        .then(() => {

          resolve(calendarEventsList);
        });
    }
  });
  return result
};

mock.onGet("/apps/calendar/events").reply(async (config) => {
  let firebaseEvents = await calendarData();
  // Get requested calendars as Array
  const calendars = config.params.calendars.split(",");

  return [
    200,
    firebaseEvents.filter((event) =>
      calendars.includes(event.extendedProps.calendar)
    ),
  ];
});

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------

mock.onPost("/apps/calendar/events").reply(async (config) => {
  // Get event from post data
  var { event } = JSON.parse(config.data);
  // console.log(store.state.mainUserCodes.RecruiterCode)
  var uid = firebase.auth().currentUser.uid
  if (store.$app.$acl.get[0] == 'recruiter') {
    event.RecruiterId = firebase.auth().currentUser.uid;
    event.RecruiterCode = store.state.mainUserCodes.RecruiterCode
  }
  else {
    event.TPOid = firebase.auth().currentUser.uid;
  }
  var collegeCode = store.state.mainUserCodes.CollegeCode;
  var obj = {
    ...event,
    startDate: new Date(event.start),
    endDate: new Date(event.end),
    timestamp: new Date(),
    calendar: event.extendedProps.calendar,
    fromCollege: collegeCode
  };
  if (store.$app.$acl.get[0] == 'recruiter') {
    await firebase.firestore().collection("RecruiterId").doc(uid).collection("CalendarEvents").doc()
      .set(obj)
      .then(() => {
        console.log("saved");
      }).catch((err) => console.log(err));
  } else {
    await firebase
      .firestore()
      .collection("CollegeCode")
      .doc(collegeCode)
      .collection("CalendarEvents").doc()
      .set(obj)
      .then(() => {
        console.log("saved");
      }).catch((err) => console.log(err));
  }

  return [201, { event }];
});

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------

mock.onPost(/\/apps\/calendar\/events\/*/).reply(async (config) => {
  // const { event: eventData } = JSON.parse(config.data);

  // // Convert Id to number
  // eventData.id = Number(eventData.id);

  // const event = data.events.find((e) => e.id === Number(eventData.id));
  // Object.assign(event, eventData);

  var { event } = JSON.parse(config.data);
  event.TPOid = firebase.auth().currentUser.uid;
  var collegeCode = store.state.mainUserCodes.CollegeCode;
  var obj = {
    ...event,
    startDate: new Date(event.start),
    endDate: new Date(event.end),
    updatedTimestamp: new Date(),
    calendar: event.extendedProps.calendar,
  };
  await firebase
    .firestore()
    .collection("CollegeCode")
    .doc(collegeCode)
    .collection("CalendarEvents").doc(event.id)
    .set(obj, { merge: true })
    .then(() => {
      console.log("saved");
    }).catch((err) => console.log(err));

  return [200, { event }];
});

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/apps\/calendar\/events\/*/).reply(async (config) => {
  // Get event id from URL
  let eventId = config.url.substring(config.url.lastIndexOf("/"));
  console.log(eventId);
  console.log(config)

  // Convert Id to number
  // eventId = Number(eventId);
  var collegeCode = store.state.mainUserCodes.CollegeCode;

  // const eventIndex = data.events.findIndex((e) => e.id === eventId);
  // data.events.splice(eventIndex, 1);
  await firebase
    .firestore()
    .collection("CollegeCode")
    .doc(collegeCode)
    .collection("CalendarEvents").doc(eventId)
    .set({
      isDeleted: true,
      deleteTimestamp: new Date()
    }, { merge: true })
    .then(() => {
      console.log("deleted");
    }).catch((err) => console.log(err));
  return [200];
});
