import Vue from "vue"
import { AclInstaller, AclCreate, AclRule } from "vue-acl"
import router from "@/router"

Vue.use(AclInstaller)

let initialRole = "public"

let userInfo = JSON.parse(localStorage.getItem("userInfo")) 
if(userInfo && userInfo.userRole) initialRole = userInfo.userRole

export default new AclCreate({
  initial: initialRole,
  notfound: "/pages/miscellaneous/not-authorized",
  router,
  acceptLocalRules: true,
  globalRules: {
    admin: new AclRule("admin").generate(),
    recruiter:new AclRule("recruiter").generate(), 
    public: new AclRule("public").or("admin").or("recruiter").generate(),
  },
  middleware: async acl => {
    await console.log('here middleware calling') // call your api
    //acl.change(initialRole)
  }
})
