import EventEmitter from 'events'
import adapter from 'webrtc-adapter';
 
const configuration = {
    iceServers:[
        {
            urls:['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302'],
        }
    ]
}

export default class  PeerConnection extends EventEmitter {
    rtcPeerConnection = null;
    remoteStream = null;
    localStream = null;

    constructor(options) {
        super();
        this.localStream = options.stream;
    }
    start() {
        this.rtcPeerConnection = new RTCPeerConnection(configuration);
        this.addTrack();
        this.listenNegotiationNeeded();
        this.listenRemoteTrack();
        this.listenCandidate();
        this.emit('connected');
    }

    addTrack() {
        this.localStream.getTracks().forEach((track) => {
            this.rtcPeerConnection.addTrack(track);
        });
    }
    listenNegotiationNeeded() {
        this.rtcPeerConnection.addEventListener('negotiationneeded', () => {
            console.log('negotiationneeded');
            this.emit('negotiationneeded');
        });
    }
    listenRemoteTrack() {
        this.remoteStream = new MediaStream();
        console.log('listening remote track');
        this.rtcPeerConnection.addEventListener('track', (event) => {
            this.remoteStream.addTrack(event.track);
        });
    }

    listenCandidate() {
        console.log('listening ice candiate')
        this.rtcPeerConnection.addEventListener(
            'icecandidate',
            (event) => {
                if (event.candidate) {
                    console.log('here we have candidate');
                    console.log(event);
                    this.emit('icecandidate', event.candidate);
                }
            },
        );
    }

    async createOffer() {
        const offerSessionDescInit = await this.rtcPeerConnection.createOffer({
            offerToReceiveVideo: true,
            offerToReceiveAudio: true,
        });
        if (offerSessionDescInit) {
            const offerSdp = new RTCSessionDescription(offerSessionDescInit);
            if (offerSdp) {
                await this.rtcPeerConnection.setLocalDescription(offerSdp);
            }
            return offerSdp;
        }
    }
    async setOfferSdp(offerSdp) {
        if (offerSdp) {
            await this.rtcPeerConnection.setRemoteDescription(new RTCSessionDescription(offerSdp));
        }
    }
    async createAnswer() {
        const answerSessionDescInit = await this.rtcPeerConnection.createAnswer({
            offerToReceiveVideo: true,
            offerToReceiveAudio: true,
        });

        if (answerSessionDescInit) {
            const answerSdp = new RTCSessionDescription(answerSessionDescInit);

            if (answerSdp) {
                await this.rtcPeerConnection.setLocalDescription(answerSdp);
            }

            return answerSdp;
        }
    }

    async setAnswerSdp(answerSdp) {
        if (answerSdp) {
            await this.rtcPeerConnection.setRemoteDescription(
                new RTCSessionDescription(answerSdp),
            );
        }
    }

    async setIceCandidate(candidate) {
        await this.rtcPeerConnection.addIceCandidate(candidate);
    }
    close() {
        this.rtcPeerConnection.close();
        this.remoteStream = null;
        this.localStream = null;
        this.rtcPeerConnection = null;
    }
}