import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Vuex from 'vuex'

import { initialiaze } from '@/firebase/firebaseConfig'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import acl from '@/libs/acl/acl.js'
// import "@flaticon/flaticon-uicons/css/all/all.css";

// Global Components
import './global-components'

// Firebase

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

//boxicon ixons
import 'boxicons'
import 'boxicons/css/boxicons.min.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// require('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css')

Vue.config.productionTip = false
// Vue.prototype.$acl = acl
// Vuex.Store.prototype.$acl = acl

var app = new Vue({
  router,
  store,
  acl,
  i18n,
  render: h => h(App),
})
store.$app = app
// store.$acl = acl
app.$mount('#app')
