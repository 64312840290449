import {
  JOIN,
  JOINED,
  NEW_CONNECTION,
  START,
  USER_LEFT,
  END,
  LEAVE,
  MEETING_ENDED,
  VIDEO_TOGGLE,
  AUDIO_TOGGLE,
  CONNECTION_SETTING_CHANGE,
  SEND_MESSAGE,
  RECIEVE_MESSAGE
} from "./moduleInterviewState";
import Meeting from "@/views/apps/videoCall/lib/meeting/meeting";
import router from "@/router";

const initialState = {
  stream: null,
  connections: [],
  userId: "",
  started: false,
  meetingId: "",
  name: "",
  meeting: null,
  meetingDetail: null,
  videoEnabled: true,
  audioEnabled: true,
  messages: []
};
export default {
  setStudentData({commit}, payload){
    // console.log(payload);
    commit("SET_STUDENT_DATA", payload);
  },
  start({ dispatch, commit }, payload) {
    localStorage.setItem("name", payload.name);
    localStorage.setItem("meetingId", payload.meetingId);
    const newPayload = {
      name: payload.name,
      meetingId: payload.meetingId
    };
    // var payload2 = this.meetingInitialState
    // console.log(payload2);
    // dispatch('meetingInitialState' , newPayload);
    commit("START", newPayload);
  },
  async join({ dispatch, commit }, payload) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true
      });
      const meeting = new Meeting({
        meetingId: payload.meetingDetail.id,
        stream: stream,
        name: payload.name,
        userId: payload.userId
      });
      console.log(meeting)
      console.log('on acion joined')
      console.log(stream.getAudioTracks());

      // meeting.on('connection', (connection) => {
      //     console.log(connection)
      //     // dispatch('newConnection' , connection);
      // });
      const newPayload = {
        meetingDetail: payload.meetingDetail,
        stream: stream,
        userId: payload.userId,
        name: payload.name,
        meeting:meeting
      };
      commit("JOIN", newPayload);
      // router.push({
      //   path: `/apps/start-interview/${payload.meetingDetail.id}`
      // });
    } catch (error) {
      console.log(error);
    }
  },
  newConnection({ dispatch, commit }, connection) {
    console.log(connection);
    var newPayload = {
      connection
    };
    commit("NEW_CONNECTION", newPayload);
    // dispatch('meetingInitialState' , newPayload)
    // commit('SET_MEETING' , newPayload)
  },
  joined({ dispatch, commit }) {
    const payload = {};
    // dispatch('meetingInitialState' , payload)
    commit("JOINED", payload);
  },
  checkConnection({state, commit , dispatch}){
    commit("CHECK_CONNECTION", dispatch);
  },

  handleconnectionChange({state, commit, dispatch}) {
    var meeting = state.interview.meeting;
        meeting.on("connection", (connection) => {
          // console.log(connection);
          console.log("here connecting");
          dispatch("newConnection", connection);
          // console.log(this.$store.state.interview.connections)
          // this.connections = [];
          
        });
        meeting.on("user-left", (connection) => {
          dispatch("user_left", connection);
        });
        meeting.on("ended", dispatch("end", meeting));
        var payoad = "here setting changed";
        meeting.on(
          "connection-setting-changed",
          dispatch("connection_setting_change", payoad)
        );
  },
  toggleVideo({ state, commit }) {
    const meeting = new Meeting({
      meetingId: state.meetingId,
      stream: state.stream,
      name: state.name,
      userId: state.userId
    });
    //   console.log(meeting)
    // const {meeting } = state.meeting
    const videoEnabled = !!meeting.toggleVideo();
    // const videoEnabled = !state.videoEnabled
    const payload = {
      videoEnabled
    };
    commit("VIDEO_TOGGLE", payload);
  },
  connection_setting_change({state, commit}, payload){
    commit("CONNECTION_SETTING_CHANGE", payload);
  },
  toggleAudio({ dispatch, commit }, meeting) {
    // const meeting = new Meeting({
    //   meetingId: state.meetingId,
    //   stream: state.stream,
    //   name: state.name,
    //   userId: state.userId
    // });
    // const {meeting } = state.meeting
    // console.log(meeting)
    // console.log(meeting.toggleAudio());
    console.log('trying to toggele audio');
    const videoEnabled = !!meeting.toggleAudio();
    // const videoEnabled = !state.audioEnabled

    const payload = {
      videoEnabled
    };
    commit("AUDIO_TOGGLE", payload);
  },
  end({state, commit }, meeting) {
    // const meeting = new Meeting({
    //   meetingId: state.meetingId,
    //   stream: state.stream,
    //   name: state.name,
    //   userId: state.userId
    // });
    meeting.end();
    commit("END");
    // router.go(-1)
  },
  leave({state, commit }, meeting) {
    // const meeting = new Meeting({
    //   meetingId: state.meetingId,
    //   stream: state.stream,
    //   name: state.name,
    //   userId: state.userId
    // });
    meeting.leave();
    commit("LEAVE");
    // router.go(-1)

  },
  meeting_ended({ commit }) {
    commit("MEETING_ENDED");
    // router.go(-1)

  },
  user_left({ state, commit }, connection) {
      // console.log(state.connections)
    const connections = state.connections;
    // console.log(connections)
    const index = connections.findIndex(
      conn => conn.userId === connection.userId
    );
    // console.log(index)
    connections.splice(index, 1);
    const payload = {
      connections
    };
    commit("USER_LEFT", payload);
  }
};
