const initialState = {
    stream: null,
    connections: [],
    userId: '',
    started: false,
    meetingId: '',
    name: '',
    meeting: null,
    meetingDetail: null,
    videoEnabled: true,
    audioEnabled: true,
    messages: [],
};
export const START = '@meeting/start';
export const JOIN = '@meeting/join';
export const JOINED = '@meeting/joined';
export const NEW_CONNECTION = '@meeting/new-connection';
export const LEAVE = '@meeting/leave';
export const USER_LEFT = '@meeting/user-left';
export const END = '@meeting/end';
export const MEETING_ENDED = '@meeting/meeting-ended';
export const VIDEO_TOGGLE = '@meeting/video-toggle';
export const AUDIO_TOGGLE = '@meeting/audio-toggle';
export const CONNECTION_SETTING_CHANGE = '@meeting/connection-setting-change';
export const SEND_MESSAGE = '@meeting/send-message';
export const RECIEVE_MESSAGE = '@meeting/recieve-message';

export default {
    initialState: initialState,
    stream: null,
    connections: [],
    userId: '',
    started: false,
    meetingId: '',
    name: '',
    meeting: null,
    meetingDetail: null,
    videoEnabled: true,
    audioEnabled: true,
    messages: [],
    connectedStudent:null
}