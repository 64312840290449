import firebase from "firebase/app";
import "firebase/auth";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
import store from "@/store";

const userDefaults = {
  uid: 0, // From Auth
  displayName: "DSR", // From Auth
  about:
    "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
  photoURL: "", // From Auth
  status: "online",
  userRole: "admin",
  ability: [
    {
      action: "manage",
      subject: "all",
    },
  ],
};

const userInfoLocalStorage = JSON.parse(localStorage.getItem("userInfo")) || {};

const getUserInfo = () => {
  let userInfo = {};

  // Update property in user
  Object.keys(userDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    userInfo[key] = userInfoLocalStorage[key]
      ? userInfoLocalStorage[key]
      : userDefaults[key];
  });

  // Include properties from localStorage
  Object.keys(userInfoLocalStorage).forEach((key) => {
    if (userInfo[key] == undefined && userInfoLocalStorage[key] != null)
      userInfo[key] = userInfoLocalStorage[key];
  });
  // console.log(userInfo)
  return userInfo;
};

// const userCodesFunc = (function(result)){
//   console.log(result);
// }
var getUserCodes = (fn) => {
  let userCodesVar = {};

  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      var role = this.$app.$acl.get
      if(role == 'admin'){
      let Ref = firebase
        .firestore()
        .collection("TPOid")
        .doc(user.uid);
      Ref.get().then((doc) => {
        // console.log(doc.data());
        userCodesVar = {
          CollegeName: doc.data().CollegeName,
          CollegeCode: doc.data().CollegeCode,
          CollegeLogo: userInfoLocalStorage.avatar,
          StudentCode: doc.data().StudentCode,
          CollegeLocation: userInfoLocalStorage.location,
          user_id: user.uid
        };
        // console.log(userCodesVar);
        fn(userCodesVar);
      });
    }else{
      let Ref = firebase
        .firestore()
        .collection("RecruiterId")
        .doc(user.uid);
      Ref.get().then((doc) => {
        // console.log(doc.data());
        userCodesVar = {
          RecruiterCode : doc.data().RecruiterCode,
          user_id: user.uid
        };
        // console.log(userCodesVar);
        fn(userCodesVar);
      });
    }
    } else {
      userCodesVar = {};
      fn(userCodesVar);
    }
  });
};

export default {
  namespaced: true,
  state: {
    activeJobs: null,
    userCodes: {},
    AppActiveUser: getUserInfo(),
    // getFinalUserCodes: () => {
    //   // let res =
    //   return getUserCodes(function(result) {
    //     store.dispatch("updateMainUserCodes", result);
    //     return result;
    //   });
    // },
    isUserLoggedIn: () => {
      let isAuthenticated = false;

      // get firebase current user
      const firebaseCurrentUser = firebase.auth().currentUser;

      if (firebaseCurrentUser) isAuthenticated = true;
      else isAuthenticated = false;
      // console.log(firebaseCurrentUser)
      return (
        localStorage.getItem("userInfo") &&
        firebaseCurrentUser &&
        isAuthenticated
      );
    },
  },
  getters: {},
  mutations: {
    UPDATE_ACTIVE_JOBS(state, payload) {
      state.activeJobs = payload;
    },
    UPDATE_USER_INFO(state, payload) {
      // console.log(payload);
      // Get Data localStorage
      let userInfo =
        JSON.parse(localStorage.getItem("userInfo")) || state.AppActiveUser;

      for (const property of Object.keys(payload)) {
        if (payload[property] != null) {
          // If some of user property is null - user default property defined in state.AppActiveUser
          state.AppActiveUser[property] = payload[property];

          // Update key in localStorage
          userInfo[property] = payload[property];
        }
      }
      // Store data in localStorage
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    UPDATE_USER_CODES(state, payload) {
      state.userCodes = payload;
    },
  },
  actions: {
    loginAttempt({ dispatch }, payload) {
      // New payload for login action
      const newPayload = {
        userDetails: payload.userDetails,
        notify: payload.notify,
        closeAnimation: payload.closeAnimation,
      };

      // If remember_me is enabled change firebase Persistence
      if (!payload.checkbox_remember_me) {
        // Change firebase Persistence
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)

          // If success try to login
          .then(function () {
            dispatch("login", newPayload);
          })

          // If error notify
          .catch(function (err) {
            // Close animation if passed as payload
            if (payload.closeAnimation) payload.closeAnimation();

            payload.notify(
              {
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "InfoIcon",
                  variant: "danger",
                  text: err.message,
                },
              },
              {
                position: "bottom-right",
              }
            );
          });
      } else {
        // Try to login
        dispatch("login", newPayload);
      }
    },
    login({ commit, state, dispatch }, payload) {
      // If user is already logged in notify and exit
      if (state.isUserLoggedIn()) {
        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation();

        payload.notify(
          {
            component: ToastificationContent,
            props: {
              title: "Login Attempt",
              icon: "InfoIcon",
              variant: "warning",
              text: "You are already logged in!",
            },
          },
          {
            position: "bottom-right",
          }
        );

        return false;
      }

      // Try to sigin
      firebase
        .auth()
        .signInWithEmailAndPassword(
          payload.userDetails.email,
          payload.userDetails.password
        )

        .then(
          (result) => {
            // Set FLAG username update required for updating username
            let isUsernameUpdateRequired = false;
            if (payload.closeAnimation) payload.closeAnimation();

            // if username update is not required
            // just reload the page to get fresh data
            // set new user data in localstorage
            if (!isUsernameUpdateRequired) {
              firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                  let Ref = firebase
                    .firestore()
                    .collection("TPOid")
                    .doc(user.uid);
                  Ref.get().then((doc) => {
                    if (doc.exists) {
                      var val = "tpo";
                      // var activejobs = [];
                      // if (typeof doc.data().activeJobs !== "undefined") {
                      //   activejobs = doc.data().activeJobs;
                      // }
                      dispatch("updateUserRole", {
                        ability: [
                          {
                            action: "manage",
                            subject: "all",
                          },
                        ],
                        role: "admin",
                      });
                      dispatch("updateUserInfo", {
                        username: doc.data().FullName,
                        profilephoto: doc.data().photo,
                      });
                      // dispatch("updateActiveJobs", activejobs);
                      // console.log(router.history.current.query.to);
                      firebase
                        .firestore()
                        .collection("CollegeCode")
                        .doc(doc.data().CollegeCode)
                        .get()
                        .then((docs) => {
                          dispatch("updateUserInfo", {
                            collegeName: docs.data().CollegeName,
                            avatar: doc.data().photo,
                            location: docs.data().CampusLocation,
                          });

                          dispatch("updateUserCodes", {
                            CollegeName: docs.data().CollegeName,
                            CollegeLocation: docs.data().CampusLocation,
                            CollegeLogo: doc.data().photo,
                            user_id: user.uid
                          });
                        })
                        .then(() => {
                          if (
                            typeof router.history.current.query.to !==
                            "undefined"
                          ) {
                            router.push(router.history.current.query.to);
                          } else {
                            // console.log("hi in store it is pushing route");
                            router.replace("/dashboard/home");
                          }
                        });
                    } else {
                      let Ref = firebase
                        .firestore()
                        .collection("RecruiterId")
                        .doc(user.uid);
                      let CollegeCode = store.state.mainUserCodes.CollegeCode;
                      Ref.get().then((doc) => {
                        console.log("printing data of recruiter")
                        // console.log(doc.data())
                        if (
                          doc.exists && typeof (doc.data().fromCollege) !== 'undefined' &&
                          doc.data().fromCollege.includes(CollegeCode)
                        ) {
                          console.log("recruiter condition satisfied")
                          var val = "recruiter";
                          // var activejobs = [];
                          // if (typeof doc.data().activeJobs !== "undefined") {
                          //   activejobs = doc.data().activeJobs;
                          // }
                          dispatch("updateUserRole", {
                            ability: [
                              {
                                action: "manage",
                                subject: "all",
                              },
                            ],
                            role: "recruiter",
                          });
                          dispatch("updateUserInfo", {
                            username: doc.data().ContactName,
                            displayName: payload.username,
                            profilephoto: doc.data().logo,
                            CompanyName: doc.data().CompanyName,
                          });
                          store.dispatch("updateMainUserCodes", {
                            user_id: user.uid,
                            RecruiterCode: doc.data().RecruiterCode,
                          });
                          // dispatch("updateActiveJobs", activejobs);

                          if (
                            typeof router.history.current.query.to !== "undefined"
                          ) {
                            router.push(router.history.current.query.to);
                          } else {
                            // console.log("hi in store it is pushing route");
                            router.replace("/recruiter/home");
                          }
                          // });
                        } else {
                          firebase
                            .auth()
                            .signOut()
                            .then(() => {
                              // this.$acl.change('editor')
                              localStorage.removeItem("userInfo");
                              payload.notify(
                                {
                                  component: ToastificationContent,

                                  props: {
                                    title: "Error",
                                    icon: "InfoIcon",
                                    variant: "danger",
                                    text:
                                      "User does not exist. Please contact support",
                                  },
                                },
                                {
                                  position: "bottom-right",
                                }
                              );
                            });
                        }
                      }).catch((err) => {
                        console.log(err)
                        firebase
                          .auth()
                          .signOut()
                          .then(() => {
                            // this.$acl.change('editor')
                            localStorage.removeItem("userInfo");
                            payload.notify(
                              {
                                component: ToastificationContent,

                                props: {
                                  title: "Error",
                                  icon: "InfoIcon",
                                  variant: "danger",
                                  text: `${err}`,
                                },
                              },
                              {
                                position: "bottom-right",
                              }
                            );
                          });
                      })
                    }
                  });
                }
              });

              commit("UPDATE_USER_INFO", result.user.providerData[0]);
            }
          },
          (err) => {
            // Close animation if passed as payload
            if (payload.closeAnimation) payload.closeAnimation();
            payload.notify(
              {
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "InfoIcon",
                  variant: "danger",
                  text: err.message,
                },
              },
              {
                position: "bottom-right",
              }
            );
          }
        );
    },
    loginWithPhone({ commit, state, dispatch }, payload) {
      if (state.isUserLoggedIn()) {
        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation();

        payload.notify(
          {
            component: ToastificationContent,
            props: {
              title: "Login Attempt",
              icon: "InfoIcon",
              variant: "warning",
              text: "You are already logged in!",
            },
          },
          {
            position: "bottom-right",
          }
        );

        return false;
      }
      let isUsernameUpdateRequired = false;
      if (payload.closeAnimation) payload.closeAnimation();

      // if username update is not required
      // just reload the page to get fresh data
      // set new user data in localstorage
      if (!isUsernameUpdateRequired) {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            let Ref = firebase
              .firestore()
              .collection("RecruiterId")
              .doc(user.uid);
            let CollegeCode = store.state.mainUserCodes.CollegeCode;
            Ref.get().then((doc) => {
              if (
                doc.exists && typeof (doc.data().fromCollege) !== 'undefined' &&
                doc.data().fromCollege.includes(CollegeCode)
              ) {
                var val = "recruiter";
                // var activejobs = [];
                // if (typeof doc.data().activeJobs !== "undefined") {
                //   activejobs = doc.data().activeJobs;
                // }
                dispatch("updateUserRole", {
                  ability: [
                    {
                      action: "manage",
                      subject: "all",
                    },
                  ],
                  role: "recruiter",
                });
                dispatch("updateUserInfo", {
                  username: doc.data().ContactName,
                  displayName: doc.data().ContactName,
                  profilephoto: doc.data().profilephoto ||  doc.data().logo,
                  avatar: doc.data().profilephoto,
                  CompanyName: doc.data().CompanyName,
                });
                store.dispatch("updateMainUserCodes", {
                  user_id: user.uid,
                  RecruiterCode: doc.data().RecruiterCode,
                });
                // dispatch("updateActiveJobs", activejobs);

                if (
                  typeof router.history.current.query.to !== "undefined"
                ) {
                  router.push(router.history.current.query.to);
                } else {
                  // console.log("hi in store it is pushing route");
                  router.replace("/recruiter/home");
                }
                // });
              } else {
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    localStorage.removeItem("userInfo");
                    payload.notify(
                      {
                        component: ToastificationContent,

                        props: {
                          title: "Error",
                          icon: "InfoIcon",
                          variant: "danger",
                          text:
                            "User does not exist. Please contact support",
                        },
                      },
                      {
                        position: "bottom-right",
                      }
                    );
                  });
              }
            });
          }
        });

        commit("UPDATE_USER_INFO", payload.userDetails.user.providerData[0]);
      }
    },
    registerWithPhone({ commit, state, dispatch }, payload) {
      if (state.isUserLoggedIn()) {
        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation();

        payload.notify(
          {
            component: ToastificationContent,
            props: {
              title: "Login Attempt",
              icon: "InfoIcon",
              variant: "warning",
              text: "You are already logged in!",
            },
          },
          {
            position: "bottom-right",
          }
        );

        return false;
      }
      // var credential = firebase.auth.EmailAuthProvider.credential(
      //   payload.email,
      //   payload.password
      // );
      // firebase
      //   .auth()
      //   .currentUser.linkWithCredential(credential)
      //   .then((usercred) => {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          // var user = usercred.user;
          console.log("Account register success", user);
          let CollegeCode = store.state.mainUserCodes.CollegeCode;
          firebase
            .firestore()
            .collection("RecruiterId")
            .doc(user.uid)
            .set({
              // email: payload.email,
              ContactName: payload.username,
              role: "recruiter",
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              ContactNo: payload.phoneNumber,
              // ContactEmail: payload.email,
              displayName: payload.username,
              fromCollege: firebase.firestore.FieldValue.arrayUnion(
                CollegeCode
              ),
            }, { merge: true })
            .then(() => {
              console.log("successfully registered");
              dispatch("updateUserRole", {
                ability: [
                  {
                    action: "manage",
                    subject: "all",
                  },
                ],
                role: "recruiter",
              });
              dispatch("updateUserInfo", {
                username: payload.username,
                displayName: payload.username,
              });
              store.dispatch("updateMainUserCodes", {
                user_id: user.uid,
              });
              if (typeof router.history.current.query.to !== "undefined") {
                router.push(router.history.current.query.to);
              } else {
                // console.log("hi in store it is pushing route");
                router.replace("/recruiter/home");
              }
              commit("UPDATE_USER_INFO", user.providerData[0]);
            })
            .catch((error) => {
              // "auth/email-already-in-use"

              console.log("Account linking error", error);
              firebase
                .auth()
                .signOut()
                .then(() => {
                  // this.$acl.change('editor')
                  localStorage.removeItem("userInfo");
                  payload.notify(
                    {
                      component: ToastificationContent,

                      props: {
                        title: "Error",
                        icon: "InfoIcon",
                        variant: "danger",
                        text: error.toString(),
                      },
                    },
                    {
                      position: "bottom-right",
                    }
                  );
                });
            });
        }
      })

    },
    updateUserCodes({ commit }, payload) {
      commit("UPDATE_USER_CODES", payload);
    },
    updateActiveJobs({ commit }, payload) {
      commit("UPDATE_ACTIVE_JOBS", payload);
    },
    updateUserRole({ dispatch }, payload) {
      // Change client side
      this._vm.$ability.update(payload.ability);
      // this.$acl.change(payload.role)

      //console.log( this._vm)
      // Make API call to server for changing role

      // Change userInfo in localStorage and store
      store.dispatch("updateAclChange", payload.role);

      dispatch("updateUserInfo", { role: payload.role, userRole: payload.role });
    },
    updateUserInfo({ commit }, payload) {
      commit("UPDATE_USER_INFO", payload);
    },
  },
};
