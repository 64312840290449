

import state from './moduleInterviewState'
import mutations from './moduleInterviewMutations'
import actions from './moduleInterviewActions'
import getters from './moduleInterviewGetters'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}