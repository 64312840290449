import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

import firebase from "firebase/app";
import "firebase/auth";

Vue.use(VueRouter)
var def = { name: 'home-dashboard' }
let userInfo = JSON.parse(localStorage.getItem("userInfo"))
if (userInfo && (userInfo.userRole === 'recruiter')) {
  def = { name: 'home-recruiter' }
}
else if (userInfo && (userInfo.userRole === 'admin')) {
  def = { name: 'home-dashboard' }
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: def },
    ...apps,
    ...dashboard,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
router.beforeEach((to, _, next) => {
  firebase.auth().onAuthStateChanged((user) => {

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser
    // if (to.meta.redirectIfLoggedIn && user) {
    //   console.log('this is redirect user if logged in')
    //       const userData = getUserData()
    //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    // }
     if (to.meta.authRequired) {
      if (!(firebaseCurrentUser)) {
        console.log('hi no current user so redirecting to login')
        return next({ name: 'auth-login' })
        // router.push({ name: 'auth-login', query: { to: to.path } })
        // router.push({ path: '/home', query: { to: to.path } })
      }
    }

    return next()
  })
  // firebase.auth().onAuthStateChanged((user) => {
  //commented from here
  //   console.log('state changed in router');
  //   const firebaseCurrentUser = firebase.auth().currentUser;
  //       const user = firebase.auth().currentUser;


  // const isLoggedIn = isUserLoggedIn()
  //  console.log(to)
  //  console.log(`hi checking cna navigate ${canNavigate(to)}`) 

  // if (!canNavigate(to)) {
  //   if (!isLoggedIn && !user ) 

  //   {console.log('yes')
  //     return next({ name: 'auth-login' })
  // }
  //   else if ((!isLoggedIn && user) || (isLoggedIn && !user)) {

  //     firebase
  //           .auth()
  //           .signOut()
  //           .then(() => {

  //             // Remove userData from localStorage
  //             localStorage.removeItem("userData");
  //             localStorage.removeItem("userInfo");

  //             // Reset ability
  //             // this.$ability.update(initialAbility);

  //             // Redirect to login page
  //             return next({ name: 'auth-login' });
  //           });
  //   }
  //   else if(isLoggedIn && user) {
  //     if (to.meta.redirectIfLoggedIn && firebaseCurrentUser) {
  //       const userData = getUserData()
  //             console.log('hi at this location 1 redirecting');

  //       next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  //     }
  //   }
  //   // If logged in => not authorized
  //   else return next({ name: 'misc-not-authorized' })
  // }

  // // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && firebaseCurrentUser) {
  //   const userData = getUserData()
  //         console.log('hi at this location 2 redirecting');
  //         console.log(userData);
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }
  // console.log('no condition needed automatically redirected');
  // return next()
  ///to here commented
  // });
})

export default router
