import mock from "@/@fake-db/mock";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import store from "@/store";
import { sortCompare } from "@/@fake-db/utils";


/* eslint-disable global-require */

const dashboardData = async () => {
  // const userData = getUserData();
  // console.log(store.state.mainUserCodes)
  var studentCode = store.state.mainUserCodes.StudentCode;
  var collegeCode = store.state.mainUserCodes.CollegeCode;
  var uid = firebase.auth().currentUser.uid;

  let finalData = {};

  let result = await new Promise((resolve, reject) => {
    // console.log('hi retriving firbase dara')
    //calculating toal students
    // firebase
    //   .firestore()
    //   .collection("StudentID")
    //   .where("StudentCode", "==", studentCode)
    //   .get()
    //   .then((docs) => {
    //     finalData.totalStudents = docs.size;
    //   })
    //   .then(() => {
    //     // if(finalData.totalStudents && finalData.totalCompanies && finalData.totalOpenings){
    //     //   console.log('resolving final data in total students')
    //     //   resolve(finalData)
    //     // }
    //   });

    //calculating total companies
    firebase
      .firestore()
      .collection("CollegeCode")
      .doc(collegeCode)
      .get()
      .then((doc) => {
        finalData.totalStudents = doc.data().TotalStudents ?? 0;
        finalData.totalPlaced = doc.data().PlacedStudents
          ? doc.data().PlacedStudents
          : 0;
        if (doc.data().JobRecruiter) {
          finalData.totalCompanies =
            doc.data().JobRecruiter.length +
            (doc.data().InternshipRecruiter.length ?? 0);
        } else {
          finalData.totalCompanies = (doc.data().JobRecruiterCode ? doc.data().JobRecruiterCode.length : 0) + (doc.data().InternshipRecruiterCode ? doc.data().InternshipRecruiterCode.length : 0)
        }

      })
      .then(() => {
        // if(finalData.totalStudents && finalData.totalCompanies && finalData.totalOpenings){
        //           console.log('resolving final data in total companies')
        //   resolve(finalData)
        // }
      });

    //getting calendar events
    var startfulldate = firebase.firestore.Timestamp.fromDate(new Date());
    firebase
      .firestore()
      .collection("CollegeCode")
      .doc(collegeCode)
      .collection("CalendarEvents")
      .orderBy("startDate", "desc")
      .where("TPOid", "==", uid)
      .where("startDate", ">=", startfulldate)
      .limit(1)
      .get()
      .then((documents) => {
        // console.log(documents.size);
        documents.forEach((doc) => {
          var startTime = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
          }).format(new Date(doc.data().startDate.toDate()));
          var endTime = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
          }).format(new Date(doc.data().endDate.toDate()));
          finalData.meetup = {
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate.toDate(),
            eventTitle: doc.data().title,
            eventDesc: doc.data().extendedProps.description,
            mediaData: [
              {
                avatar: "CalendarIcon",
                title: new Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  weekday: "short",
                }).format(new Date(doc.data().startDate.toDate())),
                subtitle: startTime + " to " + endTime,
              },
              {
                avatar: "MapPinIcon",
                title: doc.data().extendedProps.location,
                subtitle: doc.data().eventUrl,
              },
            ],
            avatars: [],
          };
        });
      });
    //calculating total job openings
    firebase
      .firestore()
      .collection("RecruiterJobs")
      .orderBy("timestamp", "desc")
      .where("Colleges", "in", ["All", studentCode])
      .get()
      .then((jobs) => {
        let totalJobOpenings = 0;
        finalData.jobSeries = [];
        finalData.companyTable = [];

        jobs.forEach((job) => {
          //adding data in company table
          if (finalData.companyTable.length < 5) {
            var jobTableObj = job.data()
            jobTableObj.id = job.id
            finalData.companyTable.push(jobTableObj);
          }
          totalJobOpenings = totalJobOpenings + parseInt(job.data().vacancies);
          finalData.totalOpenings = totalJobOpenings;
          finalData.jobSeries.push({
            companyCode: job.data().RecruiterCode,
            companyLogo: job.data().logo,
            companyName: job.data().CompanyName,
            year: job
              .data()
              .timestamp.toDate()
              .getFullYear(),
            month: job
              .data()
              .timestamp.toDate()
              .getMonth(),
            job: 1,
            ctc: job.data().ctc_upper,
          });
        });
      })
      .then(() => {
        firebase
          .firestore()
          .collection("RecruiterInternships")
          .orderBy("timestamp", "desc")
          .where("Colleges", "==", ["All", studentCode])
          .get()
          .then((jobs) => {
            let totalInternshipOpenings = 0;
            finalData.internshipSeries = [];

            jobs.forEach((job) => {
              totalInternshipOpenings =
                totalInternshipOpenings + parseInt(job.data().vacancies);
              finalData.totalInternOpenings = totalInternshipOpenings;
              finalData.internshipSeries.push({
                year: job
                  .data()
                  .timestamp.toDate()
                  .getFullYear(),
                month: job
                  .data()
                  .timestamp.toDate()
                  .getMonth(),
                job: 1,
                ctc: job.data().stipend,
              });
            });
          })
          .then(() => {
            resolve(finalData);
          });

        // }
      });
  });
  // console.log(result);
  return result;
};

//function to set months value
const setMonthFunc = (val) => {
  let currentDate = new Date();
  let currentMonth = new Date().getMonth();
  currentDate.setMonth(currentMonth - val);
  return currentDate.getMonth();
};

const getShortMonthFunc = (val) => {
  let currentDate = new Date();
  let currentMonth = new Date().getMonth();
  currentDate.setMonth(currentMonth - val);
  return currentDate.toLocaleString("default", { month: "short" });
};

mock.onGet("/main-dashboard/data").reply(async () => {
  let firebaseData = await dashboardData();
  // console.log(firebaseData)
  // let jobAarray = []

  var currentMonth = new Date().getMonth();
  var currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 9);
  var minMonth = currentDate.getMonth();

  // console.log(minMonth);
  var currentYear = new Date().getFullYear();


//// change all values to zeroes when product deployment


  let reportByMonth = {
    [setMonthFunc(8)]: 0,
    [setMonthFunc(7)]: 0,
    [setMonthFunc(6)]: 0,
    [setMonthFunc(5)]: 0,
    [setMonthFunc(4)]: 0,
    [setMonthFunc(3)]: 0,
    [setMonthFunc(2)]: 0,
    [setMonthFunc(1)]: 0,
    [currentMonth]: 0,
  };

  let InternreportByMonth = {
    [setMonthFunc(8)]: 4,
    [setMonthFunc(7)]: 6,
    [setMonthFunc(6)]: 2,
    [setMonthFunc(5)]: 8,
    [setMonthFunc(4)]: 10,
    [setMonthFunc(3)]: 1,
    [setMonthFunc(2)]: 3,
    [setMonthFunc(1)]: 2,
    [currentMonth]: 0,
  };

  let ctcReportbyYear = {
    [currentYear]: 0.0,
    [currentYear - 1]: 0.0,
    [currentYear - 2]: 0.0,
  };

  let maxctcReportbyYear = {
    [currentYear]: 0.0,
    [currentYear - 1]: 0.0,
    [currentYear - 2]: 0.0,
  };

  let ctc_average = 0.0;

  //segregating firebase data for charts

  firebaseData.jobSeries.forEach((job) => {
    //getting job openings by months
    // console.log(job);
    if (currentMonth < 9) {
      if (job.year == currentYear) {
        ctcReportbyYear[currentYear] =
          (ctcReportbyYear[currentYear] + parseFloat(job.ctc)) / 2;

        if (maxctcReportbyYear[currentYear] < parseFloat(job.ctc)) {
          maxctcReportbyYear[currentYear] = parseFloat(job.ctc);
        }
        if (reportByMonth[job.month] != null) {
          reportByMonth[job.month] = reportByMonth[job.month] + job.job;
        }
      }
      if (job.year == currentYear - 1) {
        ctcReportbyYear[currentYear - 1] =
          (ctcReportbyYear[currentYear - 1] + parseFloat(job.ctc)) / 2;
        if (maxctcReportbyYear[currentYear - 1] < parseFloat(job.ctc)) {
          maxctcReportbyYear[currentYear - 1] = parseFloat(job.ctc);
        }
        if (reportByMonth[job.month] != null) {
          reportByMonth[job.month] = reportByMonth[job.month] + job.job;
        }
      }
    } else {
      if (job.year == currentYear) {
        ctcReportbyYear[currentYear] =
          (ctcReportbyYear[currentYear] + parseFloat(job.ctc)) / 2;
        if (maxctcReportbyYear[currentYear] < parseFloat(job.ctc)) {
          maxctcReportbyYear[currentYear] = parseFloat(job.ctc);
        }
        if (reportByMonth[job.month] != null) {
          reportByMonth[job.month] = reportByMonth[job.month] + job.job;
        }
      }
    }
  });

  //segregating firebase data for charts internships here
  firebaseData.internshipSeries.forEach((job) => {
    // ctc_average = ctc_average + job.ctc
    // console.log(job);
    if (currentMonth < 9) {
      if (job.year == currentYear) {
        // ctcReportbyYear[currentYear] = (ctcReportbyYear[currentYear] + parseFloat(job.ctc))/2

        // if(maxctcReportbyYear[currentYear] < parseFloat(job.ctc)) {
        //   maxctcReportbyYear[currentYear] = parseFloat(job.ctc)
        // }
        if (InternreportByMonth[job.month] != null) {
          InternreportByMonth[job.month] =
            InternreportByMonth[job.month] + job.job;
        }
      }
      if (job.year == currentYear - 1) {
        //         ctcReportbyYear[currentYear - 1] = (ctcReportbyYear[currentYear - 1] + parseFloat(job.ctc))/2
        //         if(maxctcReportbyYear[currentYear - 1] < parseFloat(job.ctc)) {
        //   maxctcReportbyYear[currentYear - 1] = parseFloat(job.ctc)
        // }
        if (InternreportByMonth[job.month] != null) {
          InternreportByMonth[job.month] =
            InternreportByMonth[job.month] + job.job;
        }
      }
    } else {
      if (job.year == currentYear) {
        //         ctcReportbyYear[currentYear] = (ctcReportbyYear[currentYear] + parseFloat(job.ctc))/2
        //         if(maxctcReportbyYear[currentYear] < parseFloat(job.ctc)) {
        //   maxctcReportbyYear[currentYear] = parseFloat(job.ctc)
        // }
        if (InternreportByMonth[job.month] != null) {
          InternreportByMonth[job.month] =
            InternreportByMonth[job.month] + job.job;
        }
      }
    }
  });

  //getting values from segreggated data
  let jobAarray = Object.keys(reportByMonth).map((a) => reportByMonth[a]);
  let avgctcArray = Object.keys(ctcReportbyYear).map((a) => ctcReportbyYear[a]);
  let maxctcArray = Object.keys(maxctcReportbyYear).map(
    (a) => maxctcReportbyYear[a]
  );
  let internArray = Object.keys(InternreportByMonth).map(
    (a) => InternreportByMonth[a]
  );

  // console.log(jobAarray);

  //comparing top recruiters by ctc
  let thisYearJobsArray = firebaseData.jobSeries.filter(
    (arr) => arr.year > currentYear - 2
  );
  let ctcSortedArray = thisYearJobsArray.sort((a, b) =>
    parseFloat(a.ctc) > parseFloat(b.ctc)
      ? 1
      : parseFloat(b.ctc) > parseFloat(a.ctc)
        ? -1
        : 0
  );
  let topRecruiterbyCTC = ctcSortedArray.reverse().slice(0, 5);

  const dashboardAnalytics = {
    statisticsItems: [
      {
        icon: "fi-rr-user",
        color: "light-primary",
        title: firebaseData.totalStudents,
        subtitle: "Students",
        customClass: "mb-2 mb-xl-0",
      },
      {
        icon: "fi-rr-briefcase",
        color: "light-warning",
        title: firebaseData.totalOpenings,
        subtitle: "Openings",
        customClass: "mb-2 mb-xl-0",
      },
      {
        icon: "fi-rr-building",
        color: "light-danger",
        title: firebaseData.totalCompanies,
        subtitle: "Companies",
        customClass: "mb-2 mb-sm-0",
      },
      {
        icon: "fi-rr-rocket",
        color: "light-success",
        title: firebaseData.totalPlaced,
        subtitle: "Placed",
        customClass: "",
      },
    ],
    // goalOverview:{
    //   totalStudents:firebaseData.totalStudents,
    //   totalPlaced : firebaseData.totalPlaced,
    //   series : [firebaseData.totalPlaced/firebaseData.totalStudents]
    // },
    revenue: {
      years: [currentYear, currentYear - 1, currentYear - 2],
      price: avgctcArray[2],
      budget: maxctcArray[2],
      monthList: [
        getShortMonthFunc(8),
        getShortMonthFunc(7),
        getShortMonthFunc(6),
        getShortMonthFunc(5),
        getShortMonthFunc(4),
        getShortMonthFunc(3),
        getShortMonthFunc(2),
        getShortMonthFunc(1),
        new Date().toLocaleString("default", { month: "short" }),
      ],
      revenueReport: {
        series: [
          {
            name: "Jobs",
            data: jobAarray,
          },
          {
            name: "Internships",
            data: internArray,
          },
        ],
      },
      budgetChart: {
        series: [
          {
            data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
          },
          {
            data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
          },
        ],
      },
    },
    companyTable: firebaseData.companyTable,
    meetup: firebaseData.meetup,
    topRecruiterbyCTC: topRecruiterbyCTC,
    placementOverview: {
      placed: firebaseData.totalPlaced,
      inProgress: firebaseData.totalStudents - firebaseData.totalPlaced,
      series: [(firebaseData.totalPlaced / firebaseData.totalStudents * 100).toFixed(1)]
    },
  };
  return [200, dashboardAnalytics];
});

// !!!!----------------------------------------------------------------------------------------------------------!!!!
// !!!!----------------------------------------------------------------------------------------------------------!!!!
// !!!!----------------------------------------------------------------------------------------------------------!!!!
// -----------------------------------------ALL PLACEMENT REPORT DATA -----------------------------------------------
// !!!!----------------------------------------------------------------------------------------------------------!!!!
// !!!!----------------------------------------------------------------------------------------------------------!!!!
// !!!!----------------------------------------------------------------------------------------------------------!!!!

const placementData = async (val) => {
  // const userData = getUserData();
  // console.log(store.state.mainUserCodes)
  const batch = val;
  var studentCode = store.state.mainUserCodes.StudentCode;
  var collegeCode = store.state.mainUserCodes.CollegeCode;
  var uid = store.state.mainUserCodes.user_id;

  let finalData = {
    courseWiseData: [],
    totalStudents: 0,
    companySectorData: [],
    totalCompanies: 0,
    jobTypeData: [],
    totalJobs: 0,
    jobIndustryData: [],
    jobCoursesData: [],
    placementCoursesData: [],
    placementIndustryData: [],
    placementTypeData: [],
    jobCategoryData: [],
    totalJobs: [],
    internCategoryData: [],
    prevPlaceData: [],
    prevTotalPlacements: 0
  };
  let docParse = 0;
  let studentDocSize = 0;
  let result = await new Promise((resolve, reject) => {
    //course wise registration per batch
    firebase
      .firestore()
      .collection("StudentID")
      .orderBy("timestamp", "desc")
      .where("StudentCode", "==", studentCode)
      .get()
      .then((docs) => {
        // await Promise.all(
        // docs.map(
        // async (doc) => {
        // console.log("printing in student snapshot");
        // console.log(docs.size);
        // for (let doc in docs) {
        let totalStudents = 0;
        studentDocSize = docs.size;
        docs.forEach((doc) => {
          // console.log(doc);
          firebase
            .firestore()
            .collection("StudentID")
            .doc(doc.id)
            .collection("EducationDetails")
            .doc(doc.id)
            .get()
            .then((eDdoc) => {
              // console.log(eDdoc.exists);
              // console.log("Document exists");
              if (eDdoc.exists) {
                var gradDate = 0;
                var newData = eDdoc.data().newData || false;
                // console.log(`is this new data ${newData}`);
                if (newData) {
                  if (eDdoc.data().grad_date) {
                    gradDate = eDdoc
                      .data()
                      .grad_date.toDate()
                      .getFullYear();
                  }
                } else {
                  if (eDdoc.data().grad_date) {
                    gradDate = new Date(eDdoc.data().grad_date).getFullYear();
                  }
                }
                // console.log(`this is gradDate ${gradDate}`);
                if (gradDate == batch) {
                  totalStudents = totalStudents + 1;
                  // finalData.totalStudents = totalStudents;
                  let obj = {};
                  obj.course = doc.data().courseName;
                  obj.batch = gradDate;
                  obj.students = 1;
                  if (finalData.courseWiseData) {
                    var ind = finalData.courseWiseData.findIndex(
                      (i) => i.course == obj.course
                    );
                    console.log(`this is index ${ind}`);
                    if (ind == -1) {
                      finalData.courseWiseData.push(obj);
                    } else {
                      finalData.courseWiseData[ind].students =
                        finalData.courseWiseData[ind].students + 1;
                    }
                  } else {
                    finalData.courseWiseData.push(obj);
                  }
                }
              }
            })
            .then(() => {
              ///setting document snapshot
              docParse = docParse + 1;

              if (docParse == docs.size) {
                // console.log(finalData);
                finalData.totalStudents = totalStudents;
                // console.log(`this is total student ${finalData.totalStudents}`);
                // console.log(finalData.courseWiseData);
                // resolve(finalData);
              }
            });
        });
      })
      .then(() => {
        // var currentMonth = new Date().getMonth()

        var totalCompanies = 0;
        var jobsSnap = 0;
        var placedSnap = 0;
        var placedParsed = 0;
        var dateString = new Date(batch, 6, 1);
        var dateString2 = new Date(batch - 1, 5, 30);
        // if(currentMonth < 7){
        //   dateString = new Date(batch - 1 , 6, 1)

        // }

        var date = firebase.firestore.Timestamp.fromDate(dateString);
        var date2 = firebase.firestore.Timestamp.fromDate(dateString2);

        var prevDateString = new Date(batch - 1, 6, 1);
        var prevDateString2 = new Date(batch - 2, 5, 30);

        var prevdate = firebase.firestore.Timestamp.fromDate(prevDateString);
        var prevdate2 = firebase.firestore.Timestamp.fromDate(prevDateString2);
        // console.log(dateString2);
        // console.log(dateString);

        /****--------------getting placed students data from firebase ------- */

        firebase
          .firestore()
          .collection("CollegeCode")
          .doc(collegeCode)
          .collection("PlacedStudents")
          .where("grad_date", ">=", date2)
          .where("grad_date", "<=", date)
          .get()
          .then((placedDocs) => {
            console.log("printing placed students docs size");
            console.log(placedDocs.size);
            placedSnap = placedDocs.size;
            placedDocs.forEach((placed) => {
              var placeType = {
                jobType: placed.data().JobFunction,
                placedStudents: 1,
                timestamp: placed.data().timestamp,
              };
              var placeIndustryObj = {
                jobIndustry: placed.data().CompanySector,
                placedStudents: 1,
              };
              var placeCourseObj = {
                course: placed.data().CourseName,
                placed: 1,
              };

              finalData.placementTypeData.push(placeType);
              finalData.placementIndustryData.push(placeIndustryObj);
              finalData.placementCoursesData.push(placeCourseObj);
              placedParsed = placedParsed + 1;
            });
          });
        firebase
          .firestore()
          .collection("CollegeCode")
          .doc(collegeCode)
          .collection("PlacedStudents")
          .where("grad_date", ">=", prevdate2)
          .where("grad_date", "<=", prevdate)
          .get()
          .then((prevplacedDocs) => {
            finalData.prevTotalPlacements = prevplacedDocs.size
            prevplacedDocs.forEach((prev) => {
              var prevplaceObj = {
                placedStudents: 1,
                timestamp: prev.data().timestamp,
              };
              finalData.prevPlaceData.push(prevplaceObj);
            });
          });
        // .then(() => {
        //   if (
        //     jobsSnap == totalCompanies &&
        //     docParse == studentDocSize && placedParsed == placedSnap
        //   ) {
        //     // console.log("hi condition is matched in then");
        //     finalData.totalCompanies = totalCompanies;
        //     // console.log(finalData);
        //     resolve(finalData);
        //   }
        // });
        /****--------------getting recruiter internships data from firebase ------- */
        firebase
          .firestore()
          .collection("RecruiterInternships")
          .where("Colleges", "in", ["All", studentCode])
          .where("timestamp", ">=", date2)
          .where("timestamp", "<=", date)
          .get()
          .then((interns) => {
            interns.forEach((intern) => {
              let interObj = {};
              interObj.category = intern.data().category;
              interObj.company = parseInt(intern.data().vacancies) || 1;
              let pIndex = finalData.internCategoryData.findIndex(
                (i) => i.category == interObj.category
              );
              if (pIndex == -1) {
                finalData.internCategoryData.push(interObj);
              } else {
                finalData.internCategoryData[pIndex].company =
                  finalData.internCategoryData[pIndex].company +
                  interObj.company;
              }
            });
          });

        try {
          firebase
            .firestore()
            .collection("RecruiterJobs")
            .where("Colleges", "in", ["All", studentCode])
            .where("timestamp", ">=", date2)
            .where("timestamp", "<=", date)
            .get()
            .then((jobs) => {
              // console.log(jobs.size);
              // if (jobs.size > 0) {
              jobsSnap = jobs.size;
              jobs.forEach(async (job) => {
                /* -----calculating jobs by type ------- */

                finalData.totalJobs =
                  finalData.totalJobs + (parseInt(job.data().vacancies) || 1);
                let jobObj = {};
                jobObj.jobType = job.data().JobFunction || "Others";
                jobObj.company = parseInt(job.data().vacancies) || 1;

                finalData.totalJobs = finalData.totalJobs + jobObj.company;

                var ind = finalData.jobTypeData.findIndex(
                  (i) => i.jobType == jobObj.jobType
                );
                if (ind == -1) {
                  finalData.jobTypeData.push(jobObj);
                } else {
                  finalData.jobTypeData[ind].company =
                    finalData.jobTypeData[ind].company + jobObj.company;
                }
                /****--------------calculating jobs by each course ------- */
                let jobCoursesObj = {};
                jobCoursesObj.course = job.data().CourseName || [
                  "Any Graduate",
                ];
                jobCoursesObj.company = parseInt(job.data().vacancies) || 1;
                finalData.jobCoursesData.push(jobCoursesObj);

                /****--------------calculating jobs by job Category ------- */
                let jobCategoryObj = {
                  category: job.data().category,
                  company: parseInt(job.data().vacancies) || 1,
                  timestamp: job.data().timestamp.toDate(),
                };
                // console.log(jobCategoryObj)
                finalData.jobCategoryData.push(jobCategoryObj);

                // var kIndex = finalData.jobCategoryData.findIndex((i) => i.category == jobCategoryObj.category)
                // if(kIndex == -1){
                //  finalData.jobCategoryData.push(jobCategoryObj)
                // }else{
                //  finalData.jobCategoryData[kIndex].company = finalData.jobCategoryData[kIndex].company + jobCategoryObj.company
                // }

                //-------calculating recruiters by industry-----****///
                let jobIndustryObj = {};
                var recruiterCode = job.data().RecruiterCode;
                await firebase
                  .firestore()
                  .collection("RecruiterCode")
                  .doc(recruiterCode)
                  .get()
                  .then((doc) => {
                    let obj2 = {};
                    // console.log(doc.data().CompanySector);
                    obj2.companySector = doc.data().CompanySector || "Others";
                    obj2.company = 1;
                    var index = finalData.companySectorData.findIndex(
                      (i) => i.companySector == obj2.companySector
                    );
                    if (index == -1) {
                      finalData.companySectorData.push(obj2);
                    } else {
                      finalData.companySectorData[index].company =
                        finalData.companySectorData[index].company + 1;
                    }
                    totalCompanies = totalCompanies + 1;

                    /****--------------calculating jobs by each industry ------- */

                    jobIndustryObj.industry =
                      doc.data().CompanySector || "Others";
                    jobIndustryObj.company =
                      parseInt(job.data().vacancies) || 1;
                    var jIndex = finalData.jobIndustryData.findIndex(
                      (i) => i.industry == jobIndustryObj.industry
                    );
                    if (jIndex == -1) {
                      finalData.jobIndustryData.push(jobIndustryObj);
                    } else {
                      finalData.jobIndustryData[jIndex].company =
                        finalData.jobIndustryData[jIndex].company +
                        jobIndustryObj.company;
                    }
                  })
                  .then(() => {
                    // if (docParse == docs.size) {
                    //       // console.log(finalData);
                    //       resolve(finalData);
                    //     }
                    if (
                      jobs.size == totalCompanies &&
                      docParse == studentDocSize &&
                      placedParsed == placedSnap
                    ) {
                      console.log("hi condition is matched");
                      finalData.totalCompanies = totalCompanies;
                      // console.log(finalData);
                      resolve(finalData);
                    }
                  });
              });
              // if (jobs.size == 0 && docParse == studentDocSize) {
              //   finalData.totalCompanies = totalCompanies;
              //   console.log(finalData);
              //   resolve(finalData);
              // }
              // }
            })
            .then(() => { });
        } catch (err) {
          console.log(err);
        }
      });
  });
  return result;
};

mock.onPost("/placement-report/loadData").reply(async (config) => {
  const { batch } = JSON.parse(config.data);
  let firebasePlacementData = await placementData(batch);

  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****-----------------------segregating course wise registrations--------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####

  let courseSeries = [
    {
      name: "Course Details",
      data: [],
    },
  ];

  if (firebasePlacementData.courseWiseData) {
    firebasePlacementData.courseWiseData.forEach((val) => {
      var obj = {
        x: val.course,
        y: val.students,
      };
      courseSeries[0].data.push(obj);
    });
  }

  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****-----------------------seggregating industry wise recruiter data----------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####

  const labels = [];
  const companyData = [];

  if (firebasePlacementData.companySectorData.length > 0) {
    var finalArray = firebasePlacementData.companySectorData;
    finalArray.sort((a, b) => b.company > a.company);
    var arr1 = finalArray.slice(0, 9);
    var arr2 = finalArray.slice(10);
    var obj = {
      companySector: "Others",
      company: 1,
    };
    arr2.forEach((val) => (obj.company = obj.company + val.company));
    var ind = arr1.findIndex((i) => i.companySector == obj.companySector);
    if (ind == -1) {
      arr1.push(obj);
    } else {
      arr1[ind].company = arr1[ind].company + obj.company;
    }
    arr1.forEach((val) => {
      labels.push(val.companySector);
      companyData.push(val.company);
    });
  }

  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****---------------------------segregating jobs by jobs type -----------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####

  const jobTypeLabels = [];
  const jobTypeData = [];

  if (firebasePlacementData.jobTypeData.length > 0) {
    var jobTypeArray = firebasePlacementData.jobTypeData;
    jobTypeArray.sort((a, b) => b.company > a.company);
    var arr3 = jobTypeArray.slice(0, 9);
    var arr4 = jobTypeArray.slice(10);
    var jobTypeobj = {
      jobType: "Others",
      company: 1,
    };
    arr4.forEach(
      (val) => (jobTypeobj.company = jobTypeobj.company + val.company)
    );
    var index = arr3.findIndex((i) => i.jobType == jobTypeobj.jobType);
    if (index == -1) {
      arr3.push(jobTypeobj);
    } else {
      arr3[index].company = arr3[index].company + jobTypeobj.company;
    }
    arr3.forEach((val) => {
      jobTypeLabels.push(val.jobType);
      jobTypeData.push(val.company);
    });
  }
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****-------------------segregating jobs by jobs and company industry ---------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####

  const jobIndustryLabels = [];
  const jobIndustryData = [];

  if (firebasePlacementData.jobIndustryData.length > 0) {
    var jobIndustryArray = firebasePlacementData.jobIndustryData;
    jobIndustryArray.sort((a, b) => b.company > a.company);
    var arr5 = jobIndustryArray.slice(0, 9);
    var arr6 = jobIndustryArray.slice(10);
    var jobIndobj = {
      industry: "Others",
      company: 1,
    };
    arr6.forEach(
      (val) => (jobIndobj.company = jobIndobj.company + val.company)
    );
    var Iindex = arr5.findIndex((i) => i.industry == jobIndobj.industry);
    if (Iindex == -1) {
      arr5.push(jobIndobj);
    } else {
      arr5[Iindex].company = arr5[Iindex].company + jobIndobj.company;
    }
    arr5.forEach((val) => {
      jobIndustryLabels.push(val.industry);
      jobIndustryData.push(val.company);
    });
  }
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------segregating jobs by courses----- ---------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####

  let jobCoursesLabel = [];
  let jobCoursesData = [];

  if (firebasePlacementData.jobCoursesData.length > 0) {
    let jobCoursesObj = {};
    let coursesArray = firebasePlacementData.jobCoursesData;
    let segregatedCourseArray = [];
    coursesArray.forEach((val) => {
      val.course.forEach((c) => {
        var ond = {
          course: c,
          vacancy: val.company,
        };
        var kIndex = segregatedCourseArray.findIndex((i) => i.course == c);
        if (kIndex == -1) {
          segregatedCourseArray.push(ond);
        } else {
          segregatedCourseArray[kIndex].vacancy =
            segregatedCourseArray[kIndex].vacancy + ond.vacancy;
        }
      });
    });
    segregatedCourseArray.forEach((value) => {
      jobCoursesLabel.push(value.course);
      jobCoursesData.push(value.vacancy);
    });
  }
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------segregating placement data----------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####

  var placementTypeArray = [];
  let placementCoursesArray = [];
  let placementIndustryArray = [];
  let placementbyMonth = [
    { placedNo: 10, month: 0 },
    { placedNo: 76, month: 1 },
    { placedNo: 34, month: 2 },
    { placedNo: 23, month: 3 },
    { placedNo: 87, month: 4 },
    { placedNo: 37, month: 5 },
    { placedNo: 45, month: 6 },
    { placedNo: 76, month: 7 },
    { placedNo: 87, month: 8 },
    { placedNo: 23, month: 9 },
    { placedNo: 36, month: 10 },
    { placedNo: 87, month: 11 },
  ];

  /****--------------calculating placed Students by job type ------- */
  // console.log(firebasePlacementData.placementTypeData)

  firebasePlacementData.placementTypeData.forEach((placeType) => {
    var lIndex = placementTypeArray.findIndex(
      (i) => i.jobType == placeType.jobType
    );
    if (lIndex == -1) {
      placementTypeArray.push(placeType);
    } else {
      placementTypeArray[lIndex].placedStudents =
        placementTypeArray[lIndex].placedStudents + placeType.placedStudents;
    }

    /****--------------calculating placed Students by each month ------- */

    let obj = {
      placedNo: placeType.placedStudents,
      month: placeType.timestamp.toDate().getMonth(),
    };
    var oIndex = placementbyMonth.findIndex((i) => i.month == obj.month);
    if (oIndex == -1) {
      placementbyMonth.push(obj);
    } else {
      placementbyMonth[oIndex].placedNo =
        placementbyMonth[oIndex].placedNo + obj.placedNo;
    }
  });

  let placementTypeLabel = [];
  let placementTypeData = [];

  placementTypeArray.forEach((elem) => {
    placementTypeLabel.push(elem.jobType);
    placementTypeData.push(elem.placedStudents);
  });

  let placementMonthLabel = [];
  let placementMonthData = [];
  let totalPlacements = 0;

  placementbyMonth.forEach((val) => {
    placementMonthLabel.push(val.month);
    placementMonthData.push(val.placedNo);
    totalPlacements = totalPlacements + val.placedNo;
  });


  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****------------------segregating previous year placement data----------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####

  /////when sending product change all values to zero

  let prevPlacedByMonth = [
    { placedNo: 20, month: 0 },
    { placedNo: 23, month: 1 },
    { placedNo: 65, month: 2 },
    { placedNo: 37, month: 3 },
    { placedNo: 87, month: 4 },
    { placedNo: 20, month: 5 },
    { placedNo: 45, month: 6 },
    { placedNo: 64, month: 7 },
    { placedNo: 98, month: 8 },
    { placedNo: 35, month: 9 },
    { placedNo: 87, month: 10 },
    { placedNo: 30, month: 11 },
  ];
  firebasePlacementData.prevPlaceData.forEach((elem) => {
    let prevobj = {
      placedNo: elem.placedStudents,
      month: elem.timestamp.toDate().getMonth(),
    };
    var qIndex = prevPlacedByMonth.findIndex((i) => i.month == prevobj.month);
    if (qIndex == -1) {
      prevPlacedByMonth.push(prevobj);
    } else {
      prevPlacedByMonth[qIndex].placedNo =
        prevPlacedByMonth[qIndex].placedNo + prevobj.placedNo;
    }
  });
  let prevplacementMonthLabel = [];
  let prevplacementMonthData = [];
  let prevtotalPlacements = 0;
  prevPlacedByMonth.forEach((val) => {
    prevplacementMonthLabel.push(val.month);
    prevplacementMonthData.push(val.placedNo);
    prevtotalPlacements = prevtotalPlacements + val.placedNo;
  });

  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****------------------calculating placed Students by job Industry-------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  /****--------------calculating placed Students by job Industry ------- */


  firebasePlacementData.placementIndustryData.forEach((placeIndustryObj) => {
    var mIndex = placementIndustryArray.findIndex(
      (i) => i.jobIndustry == placeIndustryObj.jobIndustry
    );
    if (mIndex == -1) {
      placementIndustryArray.push(placeIndustryObj);
    } else {
      placementIndustryArray[mIndex].placedStudents =
        placementIndustryArray[mIndex].placedStudents +
        placeIndustryObj.placedStudents;
    }
  });

  let placeIndustryLabel = [];
  let placeIndustryData = [];

  placementIndustryArray.forEach((elem) => {
    placeIndustryLabel.push(elem.jobIndustry);
    placeIndustryData.push(elem.placedStudents);
  });

  /****--------------calculating placed Students by courses ------- */
  firebasePlacementData.placementCoursesData.forEach((placeCourseObj) => {
    var nIndex = placementCoursesArray.findIndex(
      (i) => i.course == placeCourseObj.course
    );
    if (nIndex == -1) {
      placementCoursesArray.push(placeCourseObj);
    } else {
      placementCoursesArray[nIndex].placed =
        placementCoursesArray[nIndex].placed + placeCourseObj.placed;
    }
  });

  let placeCourseLabel = [];
  let placeCourseData = [];

  placementCoursesArray.forEach((elem) => {
    placeCourseLabel.push(elem.course);
    placeCourseData.push(elem.placed);
  });

  // console.log(firebasePlacementData);

  /****--------------calculating total internships by category ------- */
  let internshipLabel = [];
  let internshipData = [];

  firebasePlacementData.internCategoryData.forEach((item) => {
    internshipLabel.push(item.category);
    internshipData.push(item.company);
  });

  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****----------------------------------sending final data----------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  // #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
  //
  const finalData = {
    courseSeries: courseSeries,
    totalStudents: firebasePlacementData.totalStudents,
    totalCompanies: firebasePlacementData.totalCompanies,
    companySectorData: {
      labels: labels,
      companyData: companyData,
    },
    jobTypeData: {
      labels: jobTypeLabels,
      typeData: jobTypeData,
    },
    jobIndustryData: {
      labels: jobIndustryLabels,
      industryData: jobIndustryData,
    },
    jobCoursesData: {
      labels: jobCoursesLabel,
      coursesData: jobCoursesData,
    },
    placedTypeData: {
      labels: placementTypeLabel,
      placetypeData: placementTypeData,
    },
    placedIndustryData: {
      labels: placeIndustryLabel,
      placeIndData: placeIndustryData,
    },
    placedCoursesData: {
      labels: placeCourseLabel,
      placeCourse: placeCourseData,
    },
    placedMonthData: {
      labels: placementMonthLabel,
      placeMonthNo: placementMonthData,
      // placementDataArray:placementbyMonth
      totalPlacements: totalPlacements,
    },
    prevPlacedMonthData: {

      placedMonthNo: prevplacementMonthData,
      // totalPlacements: firebasePlacementData.prevTotalPlacements
      totalPlacements : prevtotalPlacements
    },
    jobCategoryData: {
      data: firebasePlacementData.jobCategoryData,
      totalJobs: firebasePlacementData.totalJobs,
    },
    internCategoryData: {
      labels: internshipLabel,
      categoryData: internshipData,
    },
  };
  return [200, finalData];
});

// #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
// #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
// #####!!!!!!****-------------------------geeting 30 days cohort data----------------------------------****!!!!!!!#####
// #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####
// #####!!!!!!****--------------------------------------------------------------------------------------****!!!!!!!#####

const placementDataForCohorts = async (val) => {
  // const userData = getUserData();
  // console.log(store.state.mainUserCodes)
  const batch = val;
  var studentCode = store.state.mainUserCodes.StudentCode;
  var collegeCode = store.state.mainUserCodes.CollegeCode;
  var uid = store.state.mainUserCodes.user_id;
  /////when sending product change all values to zero
  let finalData = {
    placementData: {
      cohorts: [],
      data: [
        21,
        23,
        87,
        6,
        8,
        19,
        30,
        40,
        35,
        86,
        21,
        34,
        84,
        64,
        38,
        20,
        11,
        15,
        23,
        34,
        34,
        62,
        27,
        29,
        93,
        39,
        9,
        2,
        34,
        26,
      ],
    },
    offCampusjobsData: [
      38,
      20,
      11,
      15,
      23,
      34,
      34,
      62,
      27,
      29,
      21,
      23,
      87,
      6,
      8,
      19,
      30,
      40,
      35,
      86,
      21,
      34,
      84,
      64,

      93,
      39,
      9,
      2,
      34,
      26,
    ],
    onCampusjobsData: [
      9,
      8,
      7,
      6,
      5,
      4,
      3,
      2,
      1,
      0,
      10,
      11,
      19,
      18,
      21,
      8,
      3,
      4,
      6,
      4,
      2,
      1,
      0,
      0,
      0,
      0,
      0,
      7,
      8,
      9,
    ],
    internsData: [
      38,
      20,
      11,
      15,
      23,
      34,
      34,
      62,
      27,
      29,
      21,
      23,
      87,
      6,
      8,
      19,
      30,
      40,
      35,
      86,
      21,
      34,
      84,
      64,

      93,
      39,
      9,
      2,
      34,
      26,
    ],
    recruitersData: [
      2,
      5,
      6,
      7,
      9,
      2,
      4,
      1,
      8,
      6,
      7,
      10,
      3,
      7,
      8,
      2,
      4,
      3,
      9,
      1,
      13,
      22,
      5,
      3,
      6,
      9,
      1,
      2,
      3,
      6,
    ],
    eventsData: [
      2,
      6,
      3,
      5,
      7,
      2,
      4,
      8,
      9,
      2,
      3,
      4,
      7,
      7,
      4,
      8,
      2,
      8,
      2,
      6,
      9,
      1,
      0,
      0,
      0,
      0,
      3,
      5,
      3,
      10,
    ],
  };
  var d = new Date();
  d.setDate(d.getDate() - 30);

  let lastthirtyDate = firebase.firestore.Timestamp.fromDate(d);
  var cohortDate = new Date();

  let placedDocSize = 0;
  let placeParse = 0;
  let internDocSize = 0;
  let internParse = 0;
  let recDocSize = 0;
  let recParse = 0;
  let result = await new Promise((resolve, reject) => {
    let placedRef = firebase
      .firestore()
      .collection("CollegeCode")
      .doc(collegeCode)
      .collection("PlacedStudents");
    placedRef
      .where("timestamp", ">=", lastthirtyDate)
      .get()
      .then((docs) => {
        placedDocSize = docs.size;
        docs.forEach((val) => {
          var month = new Date(val.data().timestamp.toDate()).getMonth();
          console.log(`this is month ${month}`);
          finalData.placementData.data[month] =
            finalData.placementData.data[month] + 1;
          placeParse = placeParse + 1;
          // if(val.data().timestamp.toDate() >= cohort1 && val.data().timestamp.toDate() < cohort2){
          //   finalData.placementData.data[0] = finalData.placementData.data[0] + 1
          // }
          // else if(val.data().timestamp.toDate() >= cohort2 && val.data().timestamp.toDate() < cohort3){
          //   finalData.placementData.data[1] = finalData.placementData.data[1] + 1

          // }
          // else if(val.data().timestamp.toDate() >= cohort3 && val.data().timestamp.toDate() < cohort4){
          //   finalData.placementData.data[2] = finalData.placementData.data[2] + 1

          // }
          // else{
          //   finalData.placementData.data[3] = finalData.placementData.data[3] + 1

          // }
        });
      })
      .then(() => {
        let jobRef = firebase
          .firestore()
          .collection("RecruiterJobs")
          .where("Colleges", "in", ["All", studentCode])
          .where("timestamp", ">=", lastthirtyDate);
        jobRef.get().then((jobs) => {
          console.log(jobs.size);
          jobs.forEach((job) => {
            var month = new Date(job.data().timestamp.toDate()).getMonth();
            if (job.data().category == "On-Campus") {
              finalData.onCampusjobsData[month] =
                finalData.onCampusjobsData[month] +
                (parseInt(job.data().vacancies) || 1);
            } else {
              finalData.offCampusjobsData[month] =
                finalData.offCampusjobsData[month] +
                (parseInt(job.data().vacancies) || 1);
            }

            //         console.log(job.data())
            //         if(job.data().timestamp.toDate() >= cohort1 && job.data().timestamp.toDate() < cohort2){
            //   finalData.jobsData[0] = finalData.jobsData[0] + 1
            // }
            // else if(job.data().timestamp.toDate() >= cohort2 && job.data().timestamp.toDate() < cohort3){
            //   finalData.jobsData[1] = finalData.jobsData[1] + 1

            // }
            // else if(job.data().timestamp.toDate() >= cohort3 && job.data().timestamp.toDate() < cohort4){
            //   finalData.jobsData[2] = finalData.jobsData[2] + 1

            // }
            // else{
            //   finalData.jobsData[3] = finalData.jobsData[3] + 1

            // }
          });
        });
        // .then(() => {
        var internRef = firebase
          .firestore()
          .collection("RecruiterInternships")
          .where("Colleges", "in", ["All", studentCode])
          .where("timestamp", ">=", lastthirtyDate);
        internRef.get().then((interns) => {
          internDocSize = interns.size;
          interns.forEach((intern) => {
            var month = new Date(intern.data().timestamp.toDate()).getMonth();
            finalData.internsData[month] =
              finalData.internsData[month] +
              (parseInt(intern.data().vacancies) || 1);
            internParse = internParse + 1;
          });
        });
        // .then(() => {
        var recruiterRef = firebase
          .firestore()
          .collection("RecruiterId")
          .where("fromCollege", "array-contains", collegeCode);

        recruiterRef.get().then((recruiters) => {
          recDocSize = recruiters.size;

          recruiters.forEach((rec) => {
            var month = new Date(rec.data().timestamp.toDate()).getMonth();
            finalData.recruitersData[month] =
              finalData.recruitersData[month] + 1;
            recParse = recParse + 1;
          });
        });
        // })
        var eventsRef = firebase
          .firestore()
          .collection("CollegeCode")
          .doc(collegeCode)
          .collection("CalendarEvents")
          .where("startDate", ">=", lastthirtyDate);
        eventsRef
          .get()
          .then((events) => {
            events.forEach((event) => {
              var month = new Date(event.data().startDate.toDate()).getMonth();
              finalData.eventsData[month] = finalData.eventsData[month] + 1;
            });
          })
          .then(() => {
            if (
              placeParse == placedDocSize &&
              internParse == internDocSize &&
              recDocSize == recParse
            ) {
              resolve(finalData);
            }
          });
        // });
      });
  });
  return result;
};

const convertedDate = (date, date2) => {
  let formatting = { month: "short", day: "2-digit" };
  return `${new Intl.DateTimeFormat("en-US", formatting).format(
    new Date(date)
  )} - ${new Intl.DateTimeFormat("en-US", formatting).format(new Date(date2))}`;
};
mock.onPost("/placement-report/loadCohortsData").reply(async (config) => {
  const { batch } = JSON.parse(config.data);
  let firebasePlacementData = await placementDataForCohorts(batch);
  return [200, firebasePlacementData];
});
