import EventEmitter from 'events'

var TransportOptions ={
    url: '',
    canReconnect: true,
    maxRetry: 2,
}

export default class Transport extends EventEmitter {
    socket = null;
    url = '';
    canReconnect = false;
    retryCount = 0;
    maxRetry = 1;
    readyState = WebSocket.CLOSED;
    timer = null;
    closed = false;

    constructor(options) {
        super();
        this.url = options.url;
        this.canReconnect = options.canReconnect;
        this.maxRetry = options.maxRetry;
        this.connect();
    }

    connect() {
        if (this.retryCount <= this.maxRetry) {
            this.socket = new WebSocket(this.url);
            this.readyState = WebSocket.CONNECTING;
            this.retryCount++;
            this.listenEvents();
            console.log(this.socket);

        } else {
            this.emit('failed');
        }
    }

    listenEvents() {
        this.socket.addEventListener('open', () => this.handleOpen());
        this.socket.addEventListener('message', (event) =>
            this.handleMessage(event),
        );
        this.socket.addEventListener('close', () => this.handleClose());
        this.socket.addEventListener('error', (error) => this.handleError(error));
    }

    removeEvents() {
        this.socket.removeEventListener('open', () => this.handleOpen());
        this.socket.removeEventListener('message', (event) =>
            this.handleMessage(event),
        );
        this.socket.removeEventListener('close', () => this.handleClose());
        this.socket.removeEventListener('error', (error) => this.handleError(error));
    }

    handleOpen() {
        this.readyState = WebSocket.OPEN;
        this.sendHeartBeat();
        this.emit('open');
    }

    handleMessage(event) {
        const { data } = event;
        this.emit('message', data);
    }

    handleClose() {
        this.reset();
        if (!this.closed) {
            this.connect();
        }
    }

    handleError(error) {
        this.reset();
        if (!this.closed) {
            this.connect();
        }
    }

    reset() {
        clearInterval(this.timer);
        this.timer = null;
        this.removeEvents();
        this.readyState = WebSocket.CLOSED;
    }

    send(message) {
        if (this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message);
        }
    }
    sendHeartBeat() {
        this.timer = setInterval(() => {
            this.send(JSON.stringify({ type: 'heartbeat' }));
        }, 10 * 1000);
    }
    close() {
        this.closed = true;
        this.destroy();
    }
    destroy() {
        this.reset();
        this.socket.close();
        this.socket = null;
        this.url = '';
    }
    reconnect() {
        this.retryCount = 0;
        this.connect();
    }
}
