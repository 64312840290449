import mock from './mock'

/* eslint-disable import/extensions */

// JWT
import './jwt'


// Apps
import './data/apps/calendar'
import './data/apps/chat'

// dashboard
import './data/dashboard/ecommerce'
import './data/dashboard/recruiter'


// pages
import './data/pages/faq-data'
/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
